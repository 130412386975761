const ETipeFaktur = Object.freeze({
    F: 'F',
    R: 'R',
    FI: 'FI',
    FDN: 'FDN',
    RCN: 'RCN',
    SRV: 'SRV',
    SO: 'SO'
})

const ETipeFakturs = Object.freeze([
    {
        id: ETipeFaktur.F,
        decription: 'Faktur'
    },
    {
        id: ETipeFaktur.R,
        description: 'Retur'
    },
    {
        id: ETipeFaktur.FI,
        description: 'Faktur Intern'
    },
    {
        id: ETipeFaktur.FDN,
        description: 'Nota Piutang Debit'
    },
    {
        id: ETipeFaktur.RCN,
        description: 'Nota Piutang Credit'
    },
    {
        id: ETipeFaktur.SRV,
        description: 'Service or Non Stockable Transaction'
    },
    {
        id: ETipeFaktur.SO,
        description: 'Sales Order (Non Stockable)'
    },

])

export  {ETipeFaktur as default, ETipeFakturs}