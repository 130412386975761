import FMaterialMap from "@/models/f-material-map";
import FMaterial from "@/models/f-material";

export const material = {
    namespace: true,
    state: {
        itemModified: new FMaterial(),
        /**
         * Tabel Detil Material Map
         */
        itemFMaterialMap: new FMaterialMap(),
        listFMaterialMap: [],
        /**
         * Isi dari Tabel Detil Map
         */
        listFMaterial: []

    },
    actions:{
        updateItemModified({commit}, payload) {
            commit('mutateItemModified', payload)
        },

        updateItemFMaterialMap({commit}, payload){
            commit('mutateItemFMaterialMap', payload)
        },
        updateListFMaterialMap( {commit}, payload){
            commit('mutateListFMaterialMap', payload)
        },

        updateListFMaterial({commit}, payload){
            commit('mutateListFMaterial', payload)
        }
    },
    mutations:{
        mutateItemModified(state, payLoad) {
          state.itemModified = payLoad
        },

        mutateItemFMaterialMap(state, payload){
            state.itemFMaterialMap = payload
        },
        mutateListFMaterialMap(state, payload){
            state.listFMaterialMap = payload
        },
        mutateListFMaterial(state, payload){
            state.listFMaterial = payload
        }
    }

}