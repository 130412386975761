import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FPayMethodService {
    getApiUrl() {
        return API_URL
    }

    getAllFPayMethod(){
        return axios.get(API_URL + `getAllFPayMethod`, { headers: authHeader() });
    }
    getAllFPayMethodByCompany(){
        return axios.get(API_URL + `getAllFPayMethodByCompany`, { headers: authHeader() });
    }

    getAllFPayMethodContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFPayMethod`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFPayMethodContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFPayMethodById(id){
        return axios.get(API_URL + `getFPayMethodById/${id}`, { headers: authHeader() });
    }
    updateFPayMethod(item){
        return axios.put(API_URL + `updateFPayMethod/${item.id}`, item, {headers: authHeader()})
    }
    createFPayMethod(item){
        return axios.post(API_URL + `createFPayMethod`, item, {headers: authHeader()})
    }
    deleteFPayMethod(id){
        return axios.delete(API_URL + `deleteFPayMethod/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFPayMethod(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFPayMethod`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFPayMethod`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FPayMethodService()