import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FMaterialGroup1Service {
    getApiUrl() {
        return API_URL
    }

    getAllFMaterialGroup1(){
        return axios.get(API_URL + `getAllFMaterialGroup1`, { headers: authHeader() });
    }
    getAllFMaterialGroup1Containing(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFMaterialGroup1`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFMaterialGroup1Containing?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFMaterialGroup1ById(id){
        return axios.get(API_URL + `getFMaterialGroup1ById/${id}`, { headers: authHeader() });
    }
    updateFMaterialGroup1(item){
        return axios.put(API_URL + `updateFMaterialGroup1/${item.id}`, item, {headers: authHeader()})
    }
    createFMaterialGroup1(item){
        return axios.post(API_URL + `createFMaterialGroup1`, item, {headers: authHeader()})
    }
    deleteFMaterialGroup1(id){
        return axios.delete(API_URL + `deleteFMaterialGroup1/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFMaterialGroup1(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFMaterialGroup1`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFMaterialGroup1`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FMaterialGroup1Service()