import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FWarehouseService {
    getApiUrl() {
        return API_URL
    }
    getAllFWarehouse(){
        return axios.get(API_URL + `getAllFWarehouse`, { headers: authHeader() });
    }
    getAllFWarehouseByCompany(){
        return axios.get(API_URL + `getAllFWarehouseByCompany`, { headers: authHeader() });
    }
    getAllFWarehouseContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFWarehouse`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFWarehouseContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFWarehouseById(id){
        return axios.get(API_URL + `getFWarehouseById/${id}`, { headers: authHeader() });
    }
    updateFWarehouse(item){
        return axios.put(API_URL + `updateFWarehouse/${item.id}`, item, {headers: authHeader()})
    }
    createFWarehouse(item){
        return axios.post(API_URL + `createFWarehouse`, item, {headers: authHeader()})
    }
    deleteFWarehouse(id){
        return axios.delete(API_URL + `deleteFWarehouse/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFWarehouse(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFWarehouse`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFWarehouse`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FWarehouseService()