import FtSalesh from "@/models/ft-salesh";

export const salesReport = {
  namespaced: true,
  state: {
    formMode: '',
    formDetailMode: '',

    itemsFtSalesh: [ new FtSalesh() ],
  },
  actions: {
    updateFormMode({commit}, payload){
      commit('mutateFormMode', payload)
    },
    updateFormDetailMode({commit}, payload){
      commit('mutateFormDetailMode', payload)
    },

    reloadItemsFtSalesh({commit}, payload){
      commit('reloadItemsFtSalesh', payload)
    },

  },
  mutations: {

    mutateFormMode(state, payLoad){
      state.formMode = payLoad
    },
    mutateFormDetailMode(state, payLoad){
      state.formDetailMode = payLoad
    },

    reloadItemsFtSalesh(state, payLoad){
      state.itemsFtSalesh = payLoad
    },

  }

};
