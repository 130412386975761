import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FMaterialGroup3Service {
    getApiUrl() {
        return API_URL
    }

    getAllFMaterialGroup3(){
        return axios.get(API_URL + `getAllFMaterialGroup3`, { headers: authHeader() });
    }
    getAllFMaterialGroup3Containing(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFMaterialGroup3`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFMaterialGroup3Containing?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFMaterialGroup3ById(id){
        return axios.get(API_URL + `getFMaterialGroup3ById/${id}`, { headers: authHeader() });
    }
    updateFMaterialGroup3(item){
        return axios.put(API_URL + `updateFMaterialGroup3/${item.id}`, item, {headers: authHeader()})
    }
    createFMaterialGroup3(item){
        return axios.post(API_URL + `createFMaterialGroup3`, item, {headers: authHeader()})
    }
    deleteFMaterialGroup3(id){
        return axios.delete(API_URL + `deleteFMaterialGroup3/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFMaterialGroup3(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFMaterialGroup3`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFMaterialGroup3`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FMaterialGroup3Service()