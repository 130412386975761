import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FPromotionRulesdValidCustsService {
    getApiUrl() {
        return API_URL
    }
    getAllFPromotionRulesdValidCusts(){
        return axios.get(API_URL + `getAllFPromotionRulesdValidCusts`, { headers: authHeader() });
    }
    getAllFPromotionRulesdValidCustsContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFPromotionRulesdValidCusts`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFPromotionRulesdValidCustsContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFPromotionRulesdValidCustsById(id){
        return axios.get(API_URL + `getFPromotionRulesdValidCustsById/${id}`, { headers: authHeader() });
    }
    getAllFPromotionRulesdValidCustsByParent(id){
        return axios.get(API_URL + `getAllFPromotionRulesdValidCustsByParent/${id}`, { headers: authHeader() });
    }
    getAllFPromotionRulesdValidCustsByParentIds(ids){
        return axios.get(API_URL + `getAllFPromotionRulesdValidCustsByParentIds/${ids}`, { headers: authHeader() });
    }

    updateFPromotionRulesdValidCusts(item){
        return axios.put(API_URL + `updateFPromotionRulesdValidCusts/${item.id}`, item, {headers: authHeader()})
    }
    createFPromotionRulesdValidCusts(item){
        return axios.post(API_URL + `createFPromotionRulesdValidCusts`, item, {headers: authHeader()})
    }
    deleteFPromotionRulesdValidCusts(id){
        return axios.delete(API_URL + `deleteFPromotionRulesdValidCusts/${id}`, {
            headers: authHeader()
        });
    }

    deleteFPromotionRulesdValidCustsAvatarByParent(fkegiatanBean){
        return axios.delete(API_URL + `deleteFPromotionRulesdValidCustsAvatarByParent/${fkegiatanBean}`, {
            headers: authHeader()
        });
    }
    createFPromotionRulesdValidCustsAvatar(item){
        return axios.post(API_URL + `createFPromotionRulesdValidCustsAvatar`, item, {headers: authHeader()})
    }

    deleteAllFPromotionRulesdValidCusts(itemIds){

        return axios.delete(API_URL + `deleteAllFPromotionRulesdValidCusts`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FPromotionRulesdValidCustsService()