const ESalesType = Object.freeze({
    AGEN: "AGEN",
    RSL_1: "RSL_1",
    RSL_2: "RSL_2",
    CS_1: "CS_1",
    ADM_1: "ADM_1",
    OTH: "OTH"
})
const ESalesTypes = Object.freeze([
    {
        id: ESalesType.AGEN,
        description: 'Agen',
        shortName: 'AGEN'
    },
    {
        id: ESalesType.RSL_1,
        description: 'Reseller I',
        shortName: 'RSL 1'
    },
    {
        id: ESalesType.RSL_2,
        description: 'Reseller II',
        shortName: 'RSL 2'
    },
    {
        id: ESalesType.CS_1,
        description: 'CS',
        shortName: 'CS'
    },
    {
        id: ESalesType.ADM_1,
        description: 'Admin Pusat',
        shortName: 'ADM',
    },
    {
        id: ESalesType.OTH,
        description: 'Others',
        shortName: 'OTH'
    },
])
export { ESalesType as default, ESalesTypes }