const ERole = Object.freeze({
    ROLE_ADMIN: "ROLE_ADMIN",
    ROLE_ADMIN1: "ROLE_ADMIN1",
    ROLE_ADMIN2: "ROLE_ADMIN2",

    ROLE_USER: "ROLE_USER",
    ROLE_USER1: "ROLE_USER1",

    ROLE_SALES: "ROLE_SALES",
    // ROLE_SALES_1: "ROLE_SALES_1",
    // ROLE_SALES_2: "ROLE_SALES_2",

    ROLE_ACCOUNT: "ROLE_ACCOUNT",
    ROLE_FINANCE: "ROLE_FINANCE",


    ROLE_HUTANG: "ROLE_HUTANG",
    ROLE_HUTANG_1: "ROLE_HUTANG_1",

    ROLE_PIUTANG: "ROLE_PIUTANG",
    ROLE_PIUTANG_1: "ROLE_PIUTANG_1",

    ROLE_PURCHASE: "ROLE_PURCHASE",
    ROLE_PURCHASE_1: "ROLE_PURCHASE_1",
    ROLE_PURCHASE_2: "ROLE_PURCHASE_2",

    ROLE_INVENTORY: "ROLE_INVENTORY",
    ROLE_INVENTORY_1: "ROLE_INVENTORY_1",
    ROLE_INVENTORY_2: "ROLE_INVENTORY_2",

    ROLE_AGEN: "ROLE_AGEN",
    ROLE_RSL_1: "ROLE_RSL_1",
    ROLE_RSL_2: "ROLE_RSL_2",
    ROLE_CS: "ROLE_CS",

})

export default ERole
