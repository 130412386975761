export default class FPromotionRulesdValidProducts {
  constructor(
    id,
    fpromotionRuleshBean=0,

    validMaterialBean=0,

    validMaterialGroup3Bean=0,
    validMaterialGroup3Accumulation=false,

    validMaterialGroup2Bean=0,
    validMaterialGroup2Accumulation=false,

    validMaterialSalesBrandBean=0,
    validSalesBrandAccumulation=false,

    validVendorBean=0,
    validVendorAccumulation=false,


    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.fpromotionRuleshBean = fpromotionRuleshBean;

    this.validVendorBean = validVendorBean;
    this.validVendorAccumulation = validVendorAccumulation;

    this.validMaterialSalesBrandBean = validMaterialSalesBrandBean;
    this.validSalesBrandAccumulation = validSalesBrandAccumulation;

    this.validMaterialGroup2Bean = validMaterialGroup2Bean;
    this.validMaterialGroup2Accumulation = validMaterialGroup2Accumulation;

    this.validMaterialGroup3Bean = validMaterialGroup3Bean;
    this.validMaterialGroup3Accumulation = validMaterialGroup3Accumulation;

    this.validMaterialBean = validMaterialBean;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
