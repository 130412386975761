<template>
  <nav>
    <v-toolbar :flat="currentUser===null" elevation="0" color="#F5F5FF5F">
      <v-app-bar-nav-icon @click="drawer = !drawer" class="grey--text" v-if="currentUser"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="font-weight-light">DES</span>
        <span>GREEN</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu offset-y v-if="currentUser">
        <template v-slot:activator="{ on, attrs }" >
          <v-btn
              plain
              v-bind="attrs"
              v-on="on"
          >
            <span small>{{ currentUser.username }} </span>
            <v-avatar color="grey lighten-1" size="36">
              <v-img
                  :lazy-src="lookupImageUrlLazy(currentUser.avatarImage)"
                  :src="lookupImageUrl(currentUser)"
              ></v-img>
            </v-avatar>
          </v-btn>

        </template>
        <v-list color="grey lighten-4" >
          <v-list-item
              v-for="link in top_menus" :key="link.title" route :to="link.route"
          >
            <span class="align-center align-content-center">{{link.title}}</span>
          </v-list-item>
          <v-list-item>
            <v-btn plain elevation="0" color="gray darken-1" @click="logOut" small>
              Keluar
              <v-icon color="red">mdi-power</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>

      </v-menu>

    </v-toolbar>

     <v-navigation-drawer app class="bottom-gradient" v-model="drawer"  v-if="showNavbar">
      <v-layout column align-center>
        <v-flex class="mt-5">
            <v-img
              lazy-src="../assets/images/des-green-logo.png"
              max-height="120"
              max-width="120"
              src="../assets/images/des-green-logo.png"
            ></v-img>

        </v-flex>
      </v-layout>

       <v-layout column align-start class="mb-1 ml-6 mr-4">
        <v-flex>
          <v-btn
              x-small
              class="no-gutters"
              plain :href="`https://api.whatsapp.com/send?phone=+6282143574692&text=Hi kak, `" target="_blank"
          >
            <v-badge
                bordered
                color="green"
                icon="mdi-whatsapp"
                overlap
                small
            >
              <v-avatar
                  size="32"
              >
                <v-img
                    src="../assets/images/account_icon.png"
                ></v-img>
              </v-avatar>
            </v-badge>
          </v-btn>
          <div class="caption ml-2 mt-1">Bagus</div>
        </v-flex>
      </v-layout>
     <v-divider></v-divider>

    <v-list>

      <v-list-item route to='/dashboard-utama'>
        <v-list-item-icon>
          <v-icon>mdi-rocket</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle>Dashboard</v-list-item-subtitle>
      </v-list-item>

      <v-list-group
        v-for="nav_drawer in nav_drawers.filter(x=>x.visible)"
        :key="nav_drawer.title"
        :prepend-icon="nav_drawer.icon"
        no-action
        small
        v-model="nav_drawer.active"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="body-2" v-text="nav_drawer.title"> </v-list-item-title>
          </v-list-item-content>
        </template>

            <v-list-item
              v-for="submenu in nav_drawer.items.filter(x => x.visible)"
              :key="submenu.title"
              link
              router :to="submenu.route"  class="navbar_submenu_color" >

              <template>
                <v-list-item-content> 
                  <v-list-item-title class="body-2" v-text="submenu.title" > </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item-icon>
                <v-icon small >{{ submenu.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>

      </v-list-group>

    </v-list>

    </v-navigation-drawer>

  </nav>
</template>

<script>
import ERole from "../models/e-role";
import FileService from "../services/apiservices/file-service"
import TestService from "../services/test-service";

export default {
  components: {  },
  data() {
    return {

      top_menus: [
        { icon: 'mdi-help-box', title: 'Ubah Profile', route: '/change-profile', visible: true  },
      ],

      drawer: true,
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showNavbar() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles;
      }
      return false;
    },
    // showAdminBoard() {
    //   if (this.currentUser && this.currentUser.roles) {
    //     return this.currentUser.roles.includes(ERole.ROLE_ADMIN);
    //   }
    //   return false;
    // },
    // showPurchase() {
    //   if (this.currentUser && this.currentUser.roles) {
    //     return this.currentUser.roles.includes('ROLE_PURCHASE1');
    //   }
    //   return false;
    // },

    nav_drawers() {
      return [
        {
          title: 'Mitra',
          icon: 'mdi-account-group',
          active: false,
          visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_SALES,
            ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2, ERole.ROLE_CS,
              ERole.ROLE_PURCHASE, ERole.ROLE_PURCHASE_1, ERole.ROLE_PURCHASE_2,
              ERole.ROLE_INVENTORY_1, ERole.ROLE_INVENTORY_2, ERole.ROLE_AGEN].some(x =>this.currentUser.roles.includes(x)),
          items: [
            {title: 'Pelanggan', route: '/customer', icon: 'mdi-account-group', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_SALES, ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2, ERole.ROLE_CS, ERole.ROLE_SALES
              ].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Kategori', route: '/cust-category', icon: 'mdi-file-tree', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Wilayah', route: '/cust-region', icon: 'mdi-map', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Supplier', route: '/supplier', icon: 'mdi-factory', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER,
                ERole.ROLE_PURCHASE, ERole.ROLE_PURCHASE_1, ERole.ROLE_PURCHASE_2].some(x =>this.currentUser.roles.includes(x)) },
            // {title: 'Ekspedisi', route: '/expedisi', icon: 'mdi-truck-fast', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)) },
            // {title: 'MP Store', route: '/mp-store', icon: '', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_AGEN].some(x =>this.currentUser.roles.includes(x)) },
          ],
        },
        // {
        //   title: 'Pembelian',
        //   icon: 'mdi-factory',
        //   active: false,
        //   visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_PURCHASE, ERole.ROLE_PURCHASE_1, ERole.ROLE_PURCHASE_2].some(x =>this.currentUser.roles.includes(x)) ,
        //   items: [
        //     {title: 'PO', route: '/purchase-order', icon: 'mdi-email-outline', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_PURCHASE, ERole.ROLE_PURCHASE_1, ERole.ROLE_PURCHASE_2].some(x =>this.currentUser.roles.includes(x)) },
        //     {title: 'Good Receipt', route: '/purchase-gr', icon: 'mdi-truck', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_PURCHASE, ERole.ROLE_PURCHASE_1, ERole.ROLE_PURCHASE_2].some(x =>this.currentUser.roles.includes(x)) },
        //     {title: 'Invoice', route: '/purchase-invoice-type1', icon: 'mdi-credit-card-outline', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_PURCHASE, ERole.ROLE_PURCHASE_1].some(x =>this.currentUser.roles.includes(x))  },
        //   ],
        // },
        {
          title: 'Penjualan',
          icon: 'mdi-cart',
          active: false,
          visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_SALES,
              ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2, ERole.ROLE_CS, ERole.ROLE_INVENTORY,
              ERole.ROLE_INVENTORY_1, ERole.ROLE_INVENTORY_2].some(x =>this.currentUser.roles.includes(x)) ,
          items: [
            // {title: 'Input Order', route: '/sales-invoice-type1', icon: 'mdi-cart', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_SALES,  ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2, ERole.ROLE_CS ].some(x =>this.currentUser.roles.includes(x)) },
            // {title: 'Warehouse & Delivery', route: '/sales-delivery', icon: 'mdi-truck-fast', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER,  ERole.ROLE_INVENTORY,
            //     ERole.ROLE_INVENTORY_1, ERole.ROLE_INVENTORY_2].some(x =>this.currentUser.roles.includes(x)) },
            // {title: 'Return Management', route: '/sales-return-management', icon: 'mdi-cart-off', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER,  ERole.ROLE_INVENTORY,
            //     ERole.ROLE_INVENTORY_1, ERole.ROLE_INVENTORY_2].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Sales Invoice', route: '/sales-print-resi', icon: 'mdi-printer', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1, ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_SALES, ERole.ROLE_PIUTANG, ERole.ROLE_INVENTORY,
                ERole.ROLE_INVENTORY_1, ERole.ROLE_INVENTORY_2].some(x =>this.currentUser.roles.includes(x)) },
          ],
        },
        // {
        //   title: 'Pembayaran',
        //   icon: 'mdi-credit-card',
        //   active: false,
        //   visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_HUTANG, ERole.ROLE_HUTANG_1, ERole.ROLE_PIUTANG, ERole.ROLE_PIUTANG_1].some(x =>this.currentUser.roles.includes(x)) ,
        //   items: [
        //     {title: 'Invoice', route: '/pembayaran-ar-invoice', icon: 'mdi-cart', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_PIUTANG, ERole.ROLE_PIUTANG_1].some(x =>this.currentUser.roles.includes(x)) },
        //     {title: 'Hutang', route: '/pembayaran-ap', icon: 'mdi-factory', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_HUTANG, ERole.ROLE_HUTANG_1].some(x =>this.currentUser.roles.includes(x)) },
        //     {title: 'Piutang', route: '/pembayaran-ar', icon: 'mdi-account-group', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_PIUTANG, ERole.ROLE_PIUTANG_1].some(x =>this.currentUser.roles.includes(x)) },
        //     {title: 'Metode Bayar', route: '/pay-method', icon: 'mdi-credit-card', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)) },
        //   ],
        // },
        {
          title: 'Inventory',
          icon: 'mdi-credit-card',
          active: false,
          visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_SALES, ERole.ROLE_INVENTORY, ERole.ROLE_INVENTORY_1, ERole.ROLE_INVENTORY_2,
          ERole.ROLE_PURCHASE, ERole.ROLE_PURCHASE_1, ERole.ROLE_PURCHASE_2].some(x =>this.currentUser.roles.includes(x)) ,
          items: [
            // {title: 'Penyesuaian', route: '/penyesuaian-stock', icon: 'mdi-scale-balance', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_INVENTORY, ERole.ROLE_INVENTORY_1].some(x =>this.currentUser.roles.includes(x)) },
            // {title: 'Mutasi/Transfer', route: '/mutasi-transfer-stock', icon: 'mdi-share', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_INVENTORY, ERole.ROLE_INVENTORY_1].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Stock Barang', route: '/saldo-stock', icon: 'mdi-cube', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_SALES, ERole.ROLE_INVENTORY, ERole.ROLE_INVENTORY_1, ERole.ROLE_INVENTORY_2,
                ERole.ROLE_PURCHASE, ERole.ROLE_PURCHASE_1, ERole.ROLE_PURCHASE_2].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Warning-Stock', route: '/warning-stock', icon: 'mdi-exclamation', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_SALES, ERole.ROLE_INVENTORY, ERole.ROLE_INVENTORY_1, ERole.ROLE_INVENTORY_2,
                ERole.ROLE_PURCHASE, ERole.ROLE_PURCHASE_1, ERole.ROLE_PURCHASE_2].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Warehouse', route: '/warehouse', icon: 'mdi-home-variant', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)) },
          ],
        },
        {
          title: 'Produk',
          icon: 'mdi-barcode',
          active: false,
          visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)),
          items: [
            {title: 'Produk', route: '/product', icon: 'mdi-barcode', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_SALES].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Kategori', route: '/product-category', icon: 'mdi-file-tree', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_SALES].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Brand', route: '/product-sales-brand', icon: '', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)) },
            {title: 'Tax', route: '/product-ar-payment', icon: '', visible: [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)) },
          ],
        },
        // {
        //   title: 'Promotions',
        //   icon: 'mdi-gift',
        //   active: false,
        //   visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_SALES].some(x =>this.currentUser.roles.includes(x)),
        //   items: [
        //     {title: 'Setting Promosi', route: '/setting-promosi', icon: 'mdi-gift', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)) },
        //     {title: 'Report', route: '/promotions-report', icon: '', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER, ERole.ROLE_SALES].some(x =>this.currentUser.roles.includes(x)) },
        //   ],
        // },
        {
          title: 'Laporan',
          icon: 'mdi-chart-pie',
          active: false,
          visible: true,
          items: [
            {title: 'Sales to Items', route: '/reports', icon: '', visible:  true},
            {title: 'Analisis', route: '/reports-analisis', icon: 'mdi-chart-line', visible:  true},
            {title: 'Komisi', route: '/report-komisi', icon: '', visible:  true},
          ],
        },
        {
          title: 'Admin',
          icon: 'mdi-cog-outline',
          active: false,
          visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1, ERole.ROLE_ADMIN2, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)),
          items: [
            {title: 'Sistem', route: '/admin-sistem', icon: '', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1].some(x =>this.currentUser.roles.includes(x))  },
            {title: 'Struktur Organisasi', route: '/admin-struktur-organisasi', icon: 'mdi-file-tree', visible:  [ERole.ROLE_ADMIN, ERole.ROLE_ADMIN1,  ERole.ROLE_ADMIN2, ERole.ROLE_USER].some(x =>this.currentUser.roles.includes(x)) },
          ],
        },


      ]
    },

  },

  methods: {

    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    // signOut() {
    //     localStorage.setItem("authenticated", false)
    //     this.$store.commit("setAuthenticatedFalse")
    //     this.$router.push( { name: "Login"})
    // },

    lookupImageUrl(item){

      // console.log(JSON.stringify(item))
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }

    },
    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },
  },
  mounted() {
    TestService.getAdminBoard().then(
        ()=>{
        },
        error=>{
          console.log(`${error.response.status}`)
          if (error.response.status===401){
            if (this.currentUser !== undefined || this.currentUser !== ''){
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }

          }
        }
    )


  }

}
</script>


<style scoped>
  .small-line-height{
    line-height: 0.1;
  }
</style>