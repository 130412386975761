export default class FPromotionRulesh {
  constructor(
     id  =0,
     kode1   ="",
     description  ="",

     avatarImage  ="",
     fdivisionBean =0,

    /**
     * METODE PENGHITUNGAN/Pengecekan
     * 0. Dikalkulasi di akhir (Default seperti aktifitas Promo Lama) Saat Tombol SAVE
     * 1. Mulai Menghitung Saat Pembuatan Nota /Memasukkan Item dan Dihitung ulang saat Tombol SAVE
     * 2. Mulai Menghitung Saat Pembuatan Nota /Memasukkan Item dan tidak dihibung ulang saat save
     */
     calcMethod =0,

     validPeriodDateFrom  =new Date(),
     validPeriodDateTo  =new Date(),

     sharedToCompany   =false,
     statusActive   =true,

  //     ftApPaymentdEntitySet : Set<FtApPaymentdEntity> = HashSet<FtApPaymentdEntity>(),

    //	private Set<FPromotionRulesdPayments> fpromotionRulesdPaymentSet; //Dibayar menggunakan Transfer

    //	FPromotionBudget fpromotionBudget;
    //	AccAccount accAccountBean;
  //     accAccountEntityBean : AccAccountEntity? = AccAccountEntity(),
     accAccountEntityBean   = 0,

  //     accAccount_Entity_CreditBean : AccAccountEntity? = AccAccountEntity(),
     accAccount_CreditBean   = 0,
     claimPabrik   =false,

    //	private AccAccount accAccountDebitBean; //Acount Debit jika Claimable ke Pabrik
     maxTargetValue    =0.0, // 0 (nol) berarti tidak ada target sampai behenti

     maxTargetQty  =0, // 0 (nol) berarti tidak ada target sampai berhenti
     totalValueApplied    =0.0,
     totalQtyApplied  =0,
     amountPayRp    =0.0, //Menghilangkan DCV


    /*
     * VALID PRODUCT
     */
     validProductsAccumulation   =false,

  //     fpromotionRulesdValidProductsEntitySet: Set<FPromotionRulesdValidProductsEntity> = HashSet<FPromotionRulesdValidProductsEntity>(),
  //     ftSalesdPromoTprbEntitySet: Set<FtSalesdPromoTprbEntity>? = HashSet<FtSalesdPromoTprbEntity>(),
  //     ftSalesdPromoTpruDiscEntitySet: Set<FtSalesdPromoTpruDiscEntity>? = HashSet<FtSalesdPromoTpruDiscEntity>(),
     // promoDiscMethod: EnumPromoDiscFgMethod = EnumPromoDiscFgMethod.UOM1,
     promoDiscMethod="UOM1",

    /*
     * Minimum Pengambilan
     */
     discMinQtyOrValue  =0,
     discMaxQtyOrValue  =0,

    /*
     * KALAU DISC KAN PASTI KELIPATAN YA?
     * INI BERARTI TIDAK PERLU:
     * PERLU DIEVALUASI LAGI
     */
     discKelipatan   =true,//Pasti kelipatan lah
     discCashOnly   =false,
     forDiscQtyOrValueLev1    =0.0,
     disc1GetLev1    =0.0,
     disc1GetLev1_Value    =0.0,
     disc2GetLev1    =0.0,
     disc3GetLev1    =0.0,
     disc1PlusGetLev1    =0.0,
     disc2PlusGetLev1    =0.0,
     forDiscQtyOrValueLev2    =0.0,
     disc1GetLev2    =0.0,
     disc1GetLev2_Value    =0.0,
     disc2GetLev2    =0.0,
     disc3GetLev2    =0.0,
     disc1PlusGetLev2    =0.0,
     disc2PlusGetLev2    =0.0,
     forDiscQtyOrValueLev3    =0.0,
     disc1GetLev3    =0.0,
     disc1GetLev3_Value    =0.0,
     disc2GetLev3    =0.0,
     disc3GetLev3    =0.0,
     disc1PlusGetLev3    =0.0,
     disc2PlusGetLev3    =0.0,
     forDiscQtyOrValueLev4    =0.0,
     disc1GetLev4    =0.0,
     disc1GetLev4_Value    =0.0,
     disc2GetLev4    =0.0,
     disc3GetLev4    =0.0,
     disc1PlusGetLev4    =0.0,
     disc2PlusGetLev4    =0.0,
     forDiscQtyOrValueLev5    =0.0,
     disc1GetLev5    =0.0,
     disc1GetLev5_Value    =0.0,
     disc2GetLev5    =0.0,
     disc3GetLev5    =0.0,
     disc1PlusGetLev5    =0.0,
     disc2PlusGetLev5    =0.0,

    /*
     * Kadang dalam suatu promo mendapat 2 barang
     * FREE GOOD 1 METHOD: What Customers Gets
     */
     // promoFg1Method: EnumPromoDiscFgMethod = EnumPromoDiscFgMethod.UOM1,
     promoFg1Method = "UOM1",

    /*
     * Minimum Pengambilan
     */
     fg1MinQtyOrValue  =0,
     fg1Kelipatan   =true,

     freeGood1MaterialBean  = 0,
     fg1HargaJualNol   =true,

    /*
     * Dipakai untuk perhitungan berapa nominal rupiah
     *
     */
     fg1PricePcs    =0.0,

     forFg1QtyOrValueLev1  =0,
     fg1QtyGetLev1  =0,
     forFg1QtyOrValueLev2  =0,
     fg1QtyGetLev2  =0,
     forFg1QtyOrValueLev3  =0,
     fg1QtyGetLev3  =0,
     forFg1QtyOrValueLev4  =0,
     fg1QtyGetLev4  =0,
     forFg1QtyOrValueLev5  =0,
     fg1QtyGetLev5  =0,

    /*
     * Kadang dalam suatu promo mendapat 2 barang
     * FREE GOOD 2 METHOD: What Customers Gets
     */
     // promoFg2Method: EnumPromoDiscFgMethod = EnumPromoDiscFgMethod.UOM1,
     promoFg2Method= 0,

    /*
     * Minimum Pengambilan
     */
     fg2MinQtyOrValue  =0,
     fg2Kelipatan   =true,

     freeGood2MaterialBean  = 0,
     fg2HargaJualNol   =true,

    /*
     * Dipakai untuk perhitungan berapa nominal rupiah
     *
     */
     fg2PricePcs    =0.0,
     forFg2QtyOrValueLev1  =0,
     fg2QtyGetLev1  =0,
     forFg2QtyOrValueLev2  =0,
     fg2QtyGetLev2  =0,
     forFg2QtyOrValueLev3  =0,
     fg2QtyGetLev3  =0,
     forFg2QtyOrValueLev4  =0,
     fg2QtyGetLev4  =0,
     forFg2QtyOrValueLev5  =0,
     fg2QtyGetLev5  =0,

    //CASHBACK
     cashBackValue1    =0.0,
     cashBackGet1    =0.0,
     cashBackValue2    =0.0,
     cashBackGet2    =0.0,
     cashBackValue3    =0.0,
     cashBackGet3    =0.0,
     cashBackValue4    =0.0,
     cashBackGet4    =0.0,


     // ##### TAMBAHAN DISK NOTA
     discNotaCashOnly   =false,

     forDiscNotaValueLev1    =0.0,
     disc1NotaGetLev1    =0.0,
     cashBackNotaGetLev1    =0.0,
     forDiscNotaValueLev2    =0.0,
     disc1NotaGetLev2    =0.0,
     cashBackNotaGetLev2    =0.0,
     forDiscNotaValueLev3    =0.0,
     disc1NotaGetLev3    =0.0,
     cashBackNotaGetLev3    =0.0,
     forDiscNotaValueLev4    =0.0,
     disc1NotaGetLev4    =0.0,
     cashBackNotaGetLev4    =0.0,
     forDiscNotaValueLev5    =0.0,
     disc1NotaGetLev5    =0.0,
     cashBackNotaGetLev5    =0.0,


     created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.description = description;

    this.avatarImage = avatarImage;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;


    this.calcMethod = calcMethod;
    this.validPeriodDateFrom = validPeriodDateFrom;
    this.validPeriodDateTo = validPeriodDateTo;
    this.sharedToCompany = sharedToCompany;

    this.accAccountEntityBean = accAccountEntityBean;
    this.accAccount_CreditBean = accAccount_CreditBean;
    this.claimPabrik = claimPabrik;
    this.maxTargetValue = maxTargetValue;

    this.maxTargetQty =maxTargetQty;
    this.totalValueApplied  =totalValueApplied;
    this.totalQtyApplied  =totalQtyApplied;
    this.amountPayRp  =amountPayRp;

    this.validProductsAccumulation  =validProductsAccumulation;
    this.promoDiscMethod  =promoDiscMethod;

    this.discMinQtyOrValue  =discMinQtyOrValue;
    this.discMaxQtyOrValue  =discMaxQtyOrValue;


    this.discKelipatan  =discKelipatan;
    this.discCashOnly  =discCashOnly;
    this.forDiscQtyOrValueLev1  =forDiscQtyOrValueLev1;
    this.disc1GetLev1  =disc1GetLev1;
    this.disc1GetLev1_Value  =disc1GetLev1_Value;
    this.disc2GetLev1  =disc2GetLev1;
    this.disc3GetLev1  =disc3GetLev1;
    this.disc1PlusGetLev1  =disc1PlusGetLev1;
    this.disc2PlusGetLev1  =disc2PlusGetLev1;
    this.forDiscQtyOrValueLev2  =forDiscQtyOrValueLev2;
    this.disc1GetLev2  =disc1GetLev2;
    this.disc1GetLev2_Value  =disc1GetLev2_Value;
    this.disc2GetLev2  =disc2GetLev2;
    this.disc3GetLev2  =disc3GetLev2;
    this.disc1PlusGetLev2  =disc1PlusGetLev2;
    this.disc2PlusGetLev2  =disc2PlusGetLev2;
    this.forDiscQtyOrValueLev3  =forDiscQtyOrValueLev3;
    this.disc1GetLev3  =disc1GetLev3;
    this.disc1GetLev3_Value  =disc1GetLev3_Value;
    this.disc2GetLev3  =disc2GetLev3;
    this.disc3GetLev3  =disc3GetLev3;
    this.disc1PlusGetLev3  =disc1PlusGetLev3;
    this.disc2PlusGetLev3  =disc2PlusGetLev3;
    this.forDiscQtyOrValueLev4  =forDiscQtyOrValueLev4;
    this.disc1GetLev4  =disc1GetLev4;
    this.disc1GetLev4_Value  =disc1GetLev4_Value;
    this.disc2GetLev4  =disc2GetLev4;
    this.disc3GetLev4  =disc3GetLev4;
    this.disc1PlusGetLev4  =disc1PlusGetLev4;
    this.disc2PlusGetLev4  =disc2PlusGetLev4;
    this.forDiscQtyOrValueLev5  =forDiscQtyOrValueLev5;
    this.disc1GetLev5  =disc1GetLev5;
    this.disc1GetLev5_Value  =disc1GetLev5_Value;
    this.disc1GetLev5  =disc1GetLev5;
    this.disc2GetLev5  =disc2GetLev5;
    this.disc3GetLev5  =disc3GetLev5;
    this.disc1PlusGetLev5  =disc1PlusGetLev5;
    this.disc2PlusGetLev5  =disc2PlusGetLev5;

    this.disc2PlusGetLev5  =disc2PlusGetLev5;
    this.disc2PlusGetLev5  =disc2PlusGetLev5;
    this.disc2PlusGetLev5  =disc2PlusGetLev5;
    this.disc2PlusGetLev5  =disc2PlusGetLev5;
    this.disc2PlusGetLev5  =disc2PlusGetLev5;
    this.disc2PlusGetLev5  =disc2PlusGetLev5;
    this.disc2PlusGetLev5  =disc2PlusGetLev5;
    this.disc2PlusGetLev5  =disc2PlusGetLev5;
    this.disc2PlusGetLev5  =disc2PlusGetLev5;

    this.promoFg1Method  =promoFg1Method;

    this.fg1MinQtyOrValue  =fg1MinQtyOrValue;
    this.fg1Kelipatan  =fg1Kelipatan;
    this.freeGood1MaterialBean  =freeGood1MaterialBean;
    this.fg1HargaJualNol  =fg1HargaJualNol;

    this.fg1PricePcs  =fg1PricePcs;
    this.forFg1QtyOrValueLev1  =forFg1QtyOrValueLev1;
    this.fg1QtyGetLev1  =fg1QtyGetLev1;
    this.forFg1QtyOrValueLev2  =forFg1QtyOrValueLev2;
    this.fg1QtyGetLev2  =fg1QtyGetLev2;
    this.forFg1QtyOrValueLev3  =forFg1QtyOrValueLev3;
    this.fg1QtyGetLev3  =fg1QtyGetLev3;
    this.forFg1QtyOrValueLev4  =forFg1QtyOrValueLev4;
    this.fg1QtyGetLev4  =fg1QtyGetLev4;
    this.forFg1QtyOrValueLev5  =forFg1QtyOrValueLev5;
    this.fg1QtyGetLev5  =fg1QtyGetLev5;

    this.promoFg2Method  =promoFg2Method;
    this.fg2MinQtyOrValue  =fg2MinQtyOrValue;
    this.fg2Kelipatan  =fg2Kelipatan;

    this.freeGood2MaterialBean  =freeGood2MaterialBean;
    this.fg2HargaJualNol  =fg2HargaJualNol;


    this.fg2PricePcs  =fg2PricePcs;
    this.forFg2QtyOrValueLev1  =forFg2QtyOrValueLev1;
    this.fg2QtyGetLev1  =fg2QtyGetLev1;
    this.forFg2QtyOrValueLev2  =forFg2QtyOrValueLev2;
    this.fg2QtyGetLev2  =fg2QtyGetLev2;
    this.forFg2QtyOrValueLev3  =forFg2QtyOrValueLev3;
    this.fg2QtyGetLev3  =fg2QtyGetLev3;
    this.forFg2QtyOrValueLev4  =forFg2QtyOrValueLev4;
    this.fg2QtyGetLev4  =fg2QtyGetLev4;
    this.forFg2QtyOrValueLev5  =forFg2QtyOrValueLev5;
    this.fg2QtyGetLev5  =fg2QtyGetLev5;


    this.cashBackValue1  =cashBackValue1;
    this.cashBackGet1  =cashBackGet1;
    this.cashBackValue2  =cashBackValue2;
    this.cashBackGet2  =cashBackGet2;
    this.cashBackValue3  =cashBackValue3;
    this.cashBackGet3  =cashBackGet3;
    this.cashBackValue4  =cashBackValue4;
    this.cashBackGet4  =cashBackGet4;



    this.discNotaCashOnly = discNotaCashOnly;

    this.forDiscNotaValueLev1 = forDiscNotaValueLev1;
    this.disc1NotaGetLev1 = disc1NotaGetLev1;
    this.cashBackNotaGetLev1 = cashBackNotaGetLev1;
    this.forDiscNotaValueLev2 = forDiscNotaValueLev2
    this.disc1NotaGetLev2 = disc1NotaGetLev2;
    this.cashBackNotaGetLev2 = cashBackNotaGetLev2;
    this.forDiscNotaValueLev3 = forDiscNotaValueLev3;
    this.disc1NotaGetLev3 = disc1NotaGetLev3;
    this.cashBackNotaGetLev3 = cashBackNotaGetLev3;
    this.forDiscNotaValueLev4 = forDiscNotaValueLev4;
    this.disc1NotaGetLev4 = disc1NotaGetLev4;
    this.cashBackNotaGetLev4 = cashBackNotaGetLev4;
    this.forDiscNotaValueLev5 = forDiscNotaValueLev5;
    this.disc1NotaGetLev5 = disc1NotaGetLev5;
    this.cashBackNotaGetLev5 = cashBackNotaGetLev5;


    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
