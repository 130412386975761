import ETunaiKredit from './e-tunai-kredit'
import ETipeFakturJual from './e-tipe-faktur'
import ESalesType from "./e-sales-type";
import EStatusPengiriman from "./e-status-pengiriman";
import EOtherStatus from "./e-other-status";

export default class FtSalesh {
  constructor(
    id=0,

  //ORDERNO=SO
    orderno ="",

    validOrder  =false,

  //INVOICENO
    invoiceno ="",
    priority  =0,

  /*
   * ignore/reject promotion rules setting
   */
    noPromotionRules =false,
    taxNumber ="",

    taxDate = new Date(),
  /*
   * SO: FROM GOOD RECEIVE
   */
  //	@ManyToOne
  //	@JoinColumn(name="fromGoodReceiptBean", referencedColumnName="refno", nullable=true)
  //	private  FtPurchaseh fromGoodReceiptBean;
  //	@Column(name="fromGoodReceiptBean", nullable =true)
  //	private long fromGoodReceiptBean :Int =0,;
  /*
   * FAKTUR FROM SO
   */
    fakturSOBean =0,

  //	private  FtSalesh returAtasFakturBean;
  //	@Column(name="returAtasFakturBean", nullable =true)
  //	private long returAtasFakturBean :Int =0,;
  /*
   * Status Nota: (1)O-Open Sedang dikirim, (2)T-Terkirim,
   * 		(3)P-Pending Pengiriman, (4)B-Batal Nota Batal Seluruhnya
   */
  // @Enumerated(EnumType.ORDINAL)
    statusPengiriman = EStatusPengiriman.WAIT_CONFIRM.id,

  //SURAT JALAN PENGIRIMAIN = DO
    deliveryNumber ="",
    sortingCode ="",

  // @Temporal(TemporalType.DATE)
    deliveryDate =new Date(), //Jika tidak ada nomor SJ maka tidak berlaku

  //Driver
  //	@Column(name="DRIVER_NAME", length=40)
  //	private String driverName="";
  //	private FSalesman driverBean;
    driverBean =0,
    nopol ="",

  /*
   * HARUS DIGANTI MENGGUNAKAN LIST
   */
    sjPenagihanNo ="",

  // @Temporal(TemporalType.DATE)
    sjPenagihanDate =new Date(),
    collectorBean =0,

  // @Temporal(TemporalType.DATE)
    invoiceDate =new Date(),

  // @Temporal(TemporalType.DATE)
    orderDate =new Date(),

    top =0,

  // @Temporal(TemporalType.DATE)
    dueDate =new Date(),

  /*
   * SEPERTINYA KITA TIDAK PAKAI INI
   * SALDO AWAL ITU ada pada tipe transaksi
   */
  //	@Column(name="SALDO")
  //	private boolean saldo=false;
  /*
   * AMOUNT: Amount Setelah Disc1, Disc2, Disc3, +Disc1, +Disc2 pada DETIL (Amount Detil Setelah dipotong Diskon)
   */
    amountRp =0.0,
    amountPpnRp =0.0,

  
    amountRpAfterPpn =0.0,

  /*
   * berhubungan dengan Account -> menjadi apa
   * 	Kas (kas Masuk) pada
   * 		Uang Muka Penjulaan
   * Dengan Giro/Transfer, dan jika kosong maka Artinya Cash
   * Asumsi: Uang muka adalah sekali
   */
  //	@Column(name="UANG_MUKA_RP")
  //	private Double uangMukaRp=0.0;
  //	@ManyToOne
  //	@JoinColumn(name="fgiroBean", referencedColumnName="ID", nullable=true)
  //	private FGiro fgiroBean;

  /*
   * ATAS TIDAK DIPAKAI LAGI
   * Untuk SO saja
   */
    fuangMuka_SOBean =0,

    disc1  =0.0,
    disc1Rp =0.0,
    disc1PpnRp =0.0,
    disc1RpAfterPpn =0.0,

  //###TAMBAHAN
  
    amountAfterDisc1Rp =0.0,
    amountAfterDisc1PpnRp =0.0,
    amountAfterDisc1RpAfterPpn =0.0,

    disc2 =0.0,
    disc2Rp =0.0,
    disc2PpnRp =0.0,
    disc2RpAfterPpn =0.0,

  //AMOUNT AFTER DISC1 dan DISC2 dan DiscPlus
  
    amountAfterDisc2Rp =0.0,
    amountAfterDisc2PpnRp =0.0,
    amountAfterDisc2RpAfterPpn =0.0,

    discPlus_FG =0.0,
    discPlusRp_FG =0.0,
    discPlusPpnRp_FG =0.0,

  
    discPlusRpAfterPpn_FG =0.0,

  ///Jika yes maka setiap FG yang harganya nol maka akan di hitung akumulasinya, lalu nilainya ditaruh di CashBack
    calcCashBackFg =false,

  /*
   * Sama dengan bawah: Jangan Lupa
   */
  //DPP
    amountAfterDiscPlusRp_FG =0.0,

    ppnRp =0.0,

  //DPP+PPN
    amountAfterDiscPlusRpAfterPpn_FG =0.0,

  //AMOUNT PAY
    amountPayRp =0.0,

    endOfDay =false,

    openLockInputPriceAndDiscount =false,

  /*
   * REQUEST PLAFON
   */
  // @Enumerated(EnumType.ORDINAL)
    statusRequestDiscount = EOtherStatus.OPEN.id,

  // @Enumerated(EnumType.ORDINAL)
    statusRequestPlafon = EOtherStatus.OPEN.id,

    notes ="",

  // @Enumerated(EnumType.STRING)
    tunaiKredit = ETunaiKredit.T.id,
    fpayMethodBean =0,

  /* TIPE FAKTUR
   * F=FAKTUR PENJUALAN STANDART, R= RETURN PENJULAN, FI=PENJUALAN INTERN,
   * FDN= DEBIT NOTE MANUAL, RCN=RETUR CREDIT NOTE MANUAL
   * */
  // @Enumerated(EnumType.STRING)
    tipeFaktur = ETipeFakturJual.F.id,

  /* TIPE JUAL
   * SHOP=SHOPSALE, TO=TAKING ORDER, C=CANVAS, TF=TASK FORCE, D=DENTED, BS=BAD STOCK
   * */
    salesType = ESalesType.CS_1.id,

    printCounter =0,

    proses =false,

    usedSO =false,

  //1.Cash 2.Debit 3.Kartu Kredit 4.Cek 5.E-Wallet 6.Lain-lain
    tipeBayarPos =0,

    amountKasirBayar =0.0,

    fdivisionBean =0,

    fsalesmanBean =0,
    fstoreBean =0,

  /*
   *	fcustomerBean = Bill To adalah yang melakuan Order Pertama kali
   *	fcustomerShipToBean = adalah tempat pengiriman barang. jika null maka Shipto adalah BillTo
   *	fcustomerPromoToBean = adalah melakukan Switch Pengalihan Promo
   */
    fcustomerBean =0,

  //Allow Null
    fcustomerShipToBean =0,

  //Allow Null
    fwarehouseBean =0,

  /*
   * Account Mapping
   */
    accAccountArKbBean =0,
    accAccountFtSaleshCredit =0,

  /**
   * Seharusnya menggunakan interface Class Sendiri
   */
//    @Transient
//  ftSalesdItems: List<FtSalesdItemsRes> = listOf(),

    /**
     * PENGIRIMAN DAN CUSTOMER BERBEDA
     * customer umumnya adalah Bill-To(berhubungan dengan pajak) tapi bisa juga di-pisah
     * tapi pengiriman adalah Ship-To: Ditulis secara manual namun bisa ambil dari default alamat
     */

    /**
     * JNE atau JNT dari Table Tersendiri
     * ORIGIN CODE ditaruh di Division/Branch/Distributor (karena biasanya itu adalah gudang sumber)
     */
    fexpedisiBean =0,
    destCode,
    fsubAreaBean=0,

    ongkirRp = 0,
    biayaRp = 0,
    codRp =0,
    noBiaya = false,
    /**
     * ShipTo -> untuk destDistrict1 dan DestCity1 bisa
     */
    destName,
    destAddress1,
    destDistrict1,
    destCity1,
    destState1,
    destPhone,
    destCountryCode=62,
    destZipCode,

    remark,
    remarkExt1,

    listFtSalesdItems =[],

    sourceId,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;

    this.orderno = orderno;
    this.validOrder = validOrder;
    this.invoiceno = invoiceno;
    this.priority = priority;
    this.noPromotionRules = noPromotionRules;
    this.taxNumber = taxNumber;
    this.taxDate = taxDate;
    this.statusPengiriman = statusPengiriman;
    this.deliveryNumber = deliveryNumber;
    this.sortingCode = sortingCode;
    this.deliveryDate = deliveryDate;
    this.driverBean = driverBean;
    this.nopol = nopol;
    this.sjPenagihanNo = sjPenagihanNo;
    this.sjPenagihanDate = sjPenagihanDate;
    this.collectorBean = collectorBean;
    this.invoiceDate = invoiceDate;
    this.orderDate = orderDate;
    this.top = top;
    this.dueDate = dueDate;
    this.amountRp = amountRp;
    this.amountPpnRp = amountPpnRp;
    this.amountRpAfterPpn = amountRpAfterPpn;
    this.disc1 = disc1;
    this.disc1Rp = disc1Rp;
    this.disc1PpnRp = disc1PpnRp;
    this.disc1RpAfterPpn = disc1RpAfterPpn;
    this.amountAfterDisc1Rp = amountAfterDisc1Rp;
    this.amountAfterDisc1PpnRp = amountAfterDisc1PpnRp;
    this.amountAfterDisc1RpAfterPpn = amountAfterDisc1RpAfterPpn;
    this.disc2 = disc2;
    this.disc2Rp = disc2Rp;
    this.disc2PpnRp = disc2PpnRp;
    this.disc2RpAfterPpn = disc2RpAfterPpn;
    this.amountAfterDisc2Rp = amountAfterDisc2Rp;
    this.amountAfterDisc2PpnRp = amountAfterDisc2PpnRp;
    this.amountAfterDisc2RpAfterPpn = amountAfterDisc2RpAfterPpn;
    this.discPlus_FG = discPlus_FG;
    this.discPlusRp_FG = discPlusRp_FG;
    this.discPlusPpnRp_FG = discPlusPpnRp_FG;
    this.discPlusRpAfterPpn_FG = discPlusRpAfterPpn_FG;
    this.calcCashBackFg = calcCashBackFg;

    this.amountAfterDiscPlusRp_FG = amountAfterDiscPlusRp_FG;
    this.ppnRp = ppnRp;
    this.amountAfterDiscPlusRpAfterPpn_FG = amountAfterDiscPlusRpAfterPpn_FG;
    this.amountPayRp = amountPayRp;
    this.endOfDay = endOfDay;
    this.openLockInputPriceAndDiscount = openLockInputPriceAndDiscount;
    this.statusRequestDiscount = statusRequestDiscount;
    this.statusRequestPlafon = statusRequestPlafon;
    this.notes = notes;
    this.tunaiKredit = tunaiKredit;
    this.fpayMethodBean = fpayMethodBean;
    this.tipeFaktur = tipeFaktur;
    this.salesType = salesType;
    this.printCounter = printCounter;
    this.proses = proses;
    this.usedSO = usedSO;
    this.tipeBayarPos = tipeBayarPos;
    this.amountKasirBayar = amountKasirBayar;

    this.fuangMuka_SOBean = fuangMuka_SOBean;
    this.fakturSOBean = fakturSOBean;
    this.fdivisionBean = fdivisionBean;
    this.fsalesmanBean = fsalesmanBean;
    this.fstoreBean = fstoreBean;
    this.fcustomerBean = fcustomerBean;
    this.fcustomerShipToBean = fcustomerShipToBean;
    this.fwarehouseBean = fwarehouseBean;
    this.accAccountArKbBean = accAccountArKbBean;
    this.accAccountFtSaleshCredit = accAccountFtSaleshCredit;

    this.ongkirRp = ongkirRp;
    this.biayaRp = biayaRp;
    this.codRp = codRp;
    this.noBiaya = noBiaya;

    this.fsubAreaBean = fsubAreaBean;
    this.fexpedisiBean = fexpedisiBean;
    this.destCode = destCode;
    this.destName = destName;
    this.destAddress1 = destAddress1;
    this.destDistrict1 = destDistrict1;
    this.destCity1 = destCity1;
    this.destState1 = destState1;
    this.destPhone = destPhone;
    this.destCountryCode = destCountryCode;
    this.destZipCode = destZipCode;

    this.remark = remark;
    this.remarkExt1 = remarkExt1;

    this.listFtSalesdItems = listFtSalesdItems;

    this.sourceId = sourceId;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;

  }

}
