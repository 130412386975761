import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FCustomerService {
    getApiUrl() {
        return API_URL
    }

    getAllFCustomer(){
        return axios.get(API_URL + `getAllFCustomer`, { headers: authHeader() });
    }
    getAllFCustomerSimple(){
        return axios.get(API_URL + `getAllFCustomerSimple`, { headers: authHeader() });
    }
    getAllFCustomerBySalesman(fsalesmanBean){
        return axios.get(API_URL + `getAllFCustomerBySalesman/${fsalesmanBean}`, { headers: authHeader() });
    }
    getAllFCustomerBySalesmanAndCustnameContaining(fsalesmanBean, search){
        return axios.get(API_URL + `getAllFCustomerBySalesman/${fsalesmanBean}/${search}`, { headers: authHeader() });
    }

    getAllFCustomerContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFCustomer`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFCustomerContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFCustomerById(id){
        return axios.get(API_URL + `getFCustomerById/${id}`, { headers: authHeader() });
    }
    updateFCustomer(item){
        return axios.put(API_URL + `updateFCustomer/${item.id}`, item, {headers: authHeader()})
    }
    createFCustomer(item){
        return axios.post(API_URL + `createFCustomer`, item, {headers: authHeader()})
    }
    deleteFCustomer(id){
        return axios.delete(API_URL + `deleteFCustomer/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFCustomer(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFCustomer`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFCustomer`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FCustomerService()