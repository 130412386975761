import axios from 'axios';
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FExpedisiService {
    getApiUrl() {
        return API_URL
    }
    getAllFExpedisi(){
        return axios.get(API_URL + `getAllFExpedisi`, { headers: authHeader() });
    }
    getAllFExpedisiByCompany(){
        return axios.get(API_URL + `getAllFExpedisiByCompany`, { headers: authHeader() });
    }

    getAllFExpedisiContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFExpedisi`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFExpedisiContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFExpedisiById(id){
        return axios.get(API_URL + `getFExpedisiById/${id}`, { headers: authHeader() });
    }
    updateFExpedisi(item){
        return axios.put(API_URL + `updateFExpedisi/${item.id}`, item, {headers: authHeader()})
    }
    createFExpedisi(item){
        return axios.post(API_URL + `createFExpedisi`, item, {headers: authHeader()})
    }
    deleteFExpedisi(id){
        return axios.delete(API_URL + `deleteFExpedisi/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFExpedisi(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFExpedisi`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFExpedisi`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FExpedisiService()