export default class FMaterial {
  constructor(
    id,

    pcode="",
    pname="",
    avatarImage="",
    description="",

    fdivisionBean,
    statusActive=false,

    branch =false,
    childOf,

    barcode="",
    varianName="",
    freeGood=false,

    uom1="",
    uom2="",
    uom3="",
    uom4="",
    convfact1=1,
    convfact2=1,
    convfact3=1,
    pprice=0.0,
    pprice2=0.0,
    ppriceAfterPpn=0.0,
    pprice2AfterPpn=0.0,
    sprice=0.0,
    sprice2=0.0,
    spriceAfterPpn=0.0,
    sprice2AfterPpn=0.0,

    sprice2ToDist1=0.0,
    sprice2ToDist2=0.0,
    sprice2ToDist3=0.0,

    materialType='FERT',

    fwarehouseBean,
    fmaterialGroup3Bean,
    fmaterialSalesBrandBean,
    ftaxBean,
    taxable=false,
    fvendorBean,

    weightSmalest,
    caseWeight,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;

    this.pcode = pcode;
    this.pname = pname;
    this.avatarImage = avatarImage;
    this.description = description;

    this.branch =branch;
    this.childOf = childOf;

    this.barcode = barcode;
    this.varianName = varianName;
    this.freeGood = freeGood;

    this.uom1 = uom1;
    this.uom2 = uom2;
    this.uom3 = uom3;
    this.uom4 = uom4;
    this.convfact1 = convfact1;
    this.convfact2 = convfact2;
    this.convfact3 = convfact3;
    this.pprice = pprice;
    this.pprice2 = pprice2;
    this.ppriceAfterPpn = ppriceAfterPpn;
    this.pprice2AfterPpn = pprice2AfterPpn;
    this.sprice = sprice;
    this.sprice2 = sprice2;
    this.spriceAfterPpn = spriceAfterPpn;
    this.sprice2AfterPpn = sprice2AfterPpn;

    this.sprice2ToDist1 = sprice2ToDist1;
    this.sprice2ToDist2 = sprice2ToDist2;
    this.sprice2ToDist3 = sprice2ToDist3;
    /**
     * PRICE TO DISTRIBUTOR, TO CUSTOMER(ENDUSER), TO RESERVED
     */

    this.statusActive = statusActive;

    this.materialType = materialType;

    this.fwarehouseBean = fwarehouseBean;
    this.fmaterialGroup3Bean = fmaterialGroup3Bean;
    this.fmaterialSalesBrandBean = fmaterialSalesBrandBean;
    this.ftaxBean = ftaxBean;
    this.taxable = taxable;
    this.fvendorBean = fvendorBean;
    this.fdivisionBean = fdivisionBean;

    this.weightSmalest = weightSmalest;
    this.caseWeight = caseWeight;


     this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
