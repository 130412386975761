<template>
  <v-flex >
    <div class="headline font-weight-black">One Smart Software Solution</div>
    <div class="d-flex align-start mt-4">
      DES Green menghadirkan layanan terbaik untuk memenuhi kebutuhan produktivitas dan kredibilitas bisnis Anda
    </div>
    <div class="mt-4">
      <v-btn depressed color="primary">Mulai Coba</v-btn>
      <v-btn outlined color="primary" class="ml-2">Ikuti Demo</v-btn>
    </div>
  </v-flex>

</template>
<script>
export default {
  name: "AppFooter"
};
</script>

<style scoped>
</style>
