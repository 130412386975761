<template>
  <nav>
    <v-toolbar class="blue lighten-5 elevation-0">
      <v-app-bar-nav-icon @click="drawer = !drawer" class="grey--text hidden-md-and-up" ></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <v-flex>

<!--          <v-flex class="ml-2">-->
<!--            <v-img-->
<!--                lazy-src="../assets/images/logo_sidebar_small.png"-->
<!--                max-height="60"-->
<!--                max-width="60"-->
<!--                src="../assets/images/logo_sidebar_small.png"-->
<!--            ></v-img>-->
<!--          </v-flex>-->


<!--              <span class="headline font-weight-black blue&#45;&#45;text justify-center">SASTRO</span>-->
<!--              <span class="font-weight-bold blue&#45;&#45;text justify-center"><v-avatar size="30" class="blue darken-1 white&#45;&#45;text">ID</v-avatar></span>-->
          <div class="headline font-weight-black justify-center">
            <span class="orange--text text--darken-2">DES</span>
            <span class="green--text text--darken-2">GREEN</span>
<!--            <span><v-avatar size="30" class="blue darken-1 white&#45;&#45;text">ID</v-avatar></span>-->
          </div>

<!--          <v-row no-gutters >-->
<!--            <v-col cols="12" sm="6" md="6">-->
<!--              <span class="font-weight-black small-line-height">SASTRO</span>-->
<!--            </v-col>-->
<!--            <v-col cols="12" sm="6" md="6">-->
<!--              <span class="caption font-weight-bold small-line-height">DROPSHIPDROPSHIPDROPSHIPDROPSHIPDROPSHIPDROPSHIPDROPSHIPDROPSHIP</span>-->
<!--            </v-col>-->
<!--          </v-row>-->

        </v-flex>

      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down" >
        <v-btn plain elevation="0" color="gray darken-1" route to="/home" v-if="currentUser===null">
          <span>Home</span>
        </v-btn>
      </v-toolbar-items>

<!--      <v-toolbar-items class="hidden-sm-and-down" >-->
<!--        <v-btn plain elevation="0" color="gray darken-1" route to="/public-bantuan" v-if="currentUser===null">-->
<!--          <v-icon small>mdi-help-circle-outline</v-icon>-->
<!--          <span>Bantuan</span>-->
<!--        </v-btn>-->
<!--      </v-toolbar-items>-->

<!--      <v-toolbar-items class="hidden-sm-and-down" >-->
<!--        <v-btn plain elevation="0" color="gray darken-1" route to="/public-promo" v-if="currentUser===null">-->
<!--          <span>Promo</span>-->
<!--        </v-btn>-->
<!--      </v-toolbar-items>-->


      <v-toolbar-items v-if="false">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                depressed
                color="primary"
                route to="login"
                dark
                v-bind="attrs"
                v-on="on"
            >
              Login
              <v-icon>mdi-login</v-icon>
            </v-btn>
          </template>
          <span>Login</span>
        </v-tooltip>
      </v-toolbar-items>
    </v-toolbar>

    <v-navigation-drawer app  class="bottom-gradient hidden-sm-and-up"  v-model="drawer">
      <v-layout column align-center>
        <v-flex class="mt-5">
          <v-img
              lazy-src="../assets/images/logo_sidebar_small.png"
              max-height="220"
              max-width="220"
              src="../assets/images/logo_sidebar_small.png"
          ></v-img>
        </v-flex>
      </v-layout>

      <v-divider></v-divider>
      <v-list>
        <v-list-item route to='/home'>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

<!--        <v-list-item route to='/landing-berita-agenda'>-->
<!--          <v-list-item-icon>-->
<!--            <v-icon>mdi-rss</v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-title>Berita-Agenda</v-list-item-title>-->
<!--        </v-list-item>-->
<!--        <v-list-item route to='/landing-informasi-teknis'>-->
<!--          <v-list-item-icon>-->
<!--            <v-icon>mdi-robot-industrial</v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-title>Informasi Teknis</v-list-item-title>-->
<!--        </v-list-item>-->

      </v-list>

    </v-navigation-drawer>


  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      drawer: false,

      menu_berita_agenda: [
        { icon: '', title: 'Berita', route: '/public_berita', visible: true  },
        { icon: '', title: 'Agenda', route: '/public_agenda', visible: true  },
      ],
      menu_informasi: [
        { icon: '', title: 'Tenaga Kerja', route: '/public_tenaga_kerja', visible: true  },
        { icon: '', title: 'Kontraktor', route: '/public_cv_pt', visible: true  },
      ],

    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }

  },
  methods: {
    signIn() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
}
</script>


<style scoped>
.small-line-height{
  line-height: 0.5;
}
</style>