<template>
  <v-row align="center" class="ml-8">
    <v-col cols="12" sm="6" md="6">
      <v-card elevation="0">
        <v-card-title></v-card-title>
        <v-card-text class="justify-center align-center">
          <TopLeft></TopLeft>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="6" md="6">
      <TopRight></TopRight>
    </v-col>
  </v-row>
</template>

<script>
import TopLeft from "@/components/home/TopLeft";
import TopRight from "@/components/home/TopRight";
export default {
  name: "Top",
  components: {
    TopRight,
    TopLeft,
  }
}
</script>

<style scoped>

</style>