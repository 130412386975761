<template>
  <div class="dashboard">

    <Top v-if="true"></Top>
    <Section2 v-if="false"></Section2>
    <Layanan2 v-if="false"></Layanan2>
    <Kelola v-if="false"></Kelola>

    <LayananKami></LayananKami>
    <Trust v-if="true"></Trust>
    <Testimoni v-if="true"></Testimoni>
    <Price v-if="false"></Price>
    <layanan3 v-if="false"></layanan3>


    <AppFooter></AppFooter>

  </div>
</template>

<script>
import Top from "@/components/home/Top";
import Section2 from "@/components/home/Section2";
import Layanan2 from "@/components/home/Layanan2";
import Kelola from "@/components/home/Kelola";
import Testimoni from "@/components/home/Testimoni";
import Trust from "@/components/home/Trust";
import Price from "@/components/home/Price";
import Layanan3 from "@/components/home/Layanan3";
import AppFooter from "@/components/public-area/AppFooter";
import LayananKami from "@/components/home/LayananKami";

export default {
  components: {
    LayananKami,
    AppFooter, Layanan2, Top, Section2, Kelola, Testimoni,
    Trust, Price, Layanan3
  },
  data () {
    return {
    }
  },


}
</script>

<style>
  body {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .bg-light {
    background: #FCFBFF;
  }

  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .section-head {
    margin: 0 0 30px;
  }

  .section-title {
    font-size: 1.75rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 15px;
  }

  .section-meta {
    font-size: 1rem;
    line-height: 25px;
    font-weight: 300;
    color: #777;
  }

  .section-body {
    font-weight: 300;
  }
</style>
