import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FPromotionRulesdValidProductsService {
    getApiUrl() {
        return API_URL
    }
    getAllFPromotionRulesdValidProducts(){
        return axios.get(API_URL + `getAllFPromotionRulesdValidProducts`, { headers: authHeader() });
    }
    getAllFPromotionRulesdValidProductsContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFPromotionRulesdValidProducts`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFPromotionRulesdValidProductsContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFPromotionRulesdValidProductsById(id){
        return axios.get(API_URL + `getFPromotionRulesdValidProductsById/${id}`, { headers: authHeader() });
    }
    getAllFPromotionRulesdValidProductsByParent(id){
        return axios.get(API_URL + `getAllFPromotionRulesdValidProductsByParent/${id}`, { headers: authHeader() });
    }
    getAllFPromotionRulesdValidProductsByParentIds(ids){
        return axios.get(API_URL + `getAllFPromotionRulesdValidProductsByParentIds/${ids}`, { headers: authHeader() });
    }

    updateFPromotionRulesdValidProducts(item){
        return axios.put(API_URL + `updateFPromotionRulesdValidProducts/${item.id}`, item, {headers: authHeader()})
    }
    createFPromotionRulesdValidProducts(item){
        return axios.post(API_URL + `createFPromotionRulesdValidProducts`, item, {headers: authHeader()})
    }
    deleteFPromotionRulesdValidProducts(id){
        return axios.delete(API_URL + `deleteFPromotionRulesdValidProducts/${id}`, {
            headers: authHeader()
        });
    }

    deleteFPromotionRulesdValidProductsAvatarByParent(fkegiatanBean){
        return axios.delete(API_URL + `deleteFPromotionRulesdValidProductsAvatarByParent/${fkegiatanBean}`, {
            headers: authHeader()
        });
    }
    createFPromotionRulesdValidProductsAvatar(item){
        return axios.post(API_URL + `createFPromotionRulesdValidProductsAvatar`, item, {headers: authHeader()})
    }

    deleteAllFPromotionRulesdValidProducts(itemIds){

        return axios.delete(API_URL + `deleteAllFPromotionRulesdValidProducts`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FPromotionRulesdValidProductsService()