import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './auth-module'
import { data } from './data-module'
import { sales } from './sales-module'
import { aktifitaspromo} from "@/store/aktifitaspromo-module";
import {material} from "@/store/material-module"
import {salesReport} from "@/store/sales-report-module";
import { filter } from './filter-module'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        data,
        sales,
        aktifitaspromo,
        material,
        salesReport,
        filter
    }
})
