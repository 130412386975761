const ETunaiKredit = Object.freeze({
    T: {
        id: 'T',
        decription: 'Kredit'
    },
    K: {
        id: 'K',
        description: 'Tunai'
    }

})
export default ETunaiKredit