import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FCustomerGroupService {
    getApiUrl() {
        return API_URL
    }

    getAllFCustomerGroup(){
        return axios.get(API_URL + `getAllFCustomerGroup`, { headers: authHeader() });
    }
    getAllFCustomerGroupSimple(){
        return axios.get(API_URL + `getAllFCustomerGroupSimple`, { headers: authHeader() });
    }
    getAllFCustomerGroupByCompany(){
        return axios.get(API_URL + `getAllFCustomerGroupByCompany`, { headers: authHeader() });
    }
    getAllFCustomerGroupByCompanySimple(){
        return axios.get(API_URL + `getAllFCustomerGroupByCompanySimple`, { headers: authHeader() });
    }

    getAllFCustomerGroupContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFCustomerGroup`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFCustomerGroupContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFCustomerGroupById(id){
        return axios.get(API_URL + `getFCustomerGroupById/${id}`, { headers: authHeader() });
    }
    updateFCustomerGroup(item){
        return axios.put(API_URL + `updateFCustomerGroup/${item.id}`, item, {headers: authHeader()})
    }
    createFCustomerGroup(item){
        return axios.post(API_URL + `createFCustomerGroup`, item, {headers: authHeader()})
    }
    deleteFCustomerGroup(id){
        return axios.delete(API_URL + `deleteFCustomerGroup/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFCustomerGroup(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFCustomerGroup`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFCustomerGroup`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FCustomerGroupService()