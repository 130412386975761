<template>
  <section
      id="testimoni"
      class="grey lighten-3"
  >
    <div class="py-4"></div>


    <v-container class="text-center">
      <h2 class="display-1 font-weight-bold mb-10">Layanan Yang Kami Berikan</h2>

      <v-row>
        <v-col
            cols="12"
            md="4"
        >
          <v-card
              class="py-12 px-4 rounded-lg"
              color="grey lighten-5"
              flat
              elevation="14"
              height="400"
          >
            <v-theme-provider dark>
              <div>
                <v-avatar
                    size="88"
                >
                  <v-img  src="../../assets/images/des-green-logo.png"></v-img>
                </v-avatar>
              </div>
            </v-theme-provider>

            <v-card-title
                class="justify-center font-weight-black"
            >
              DES Green Software
            </v-card-title>

            <v-card-text
                class="subtitle-1"
            >
              Perangkat lunak untuk Distributor atau perusahaan dagang dan pabrik, yang berisi modul-modul ERP:
             <span class="font-italic">
                Sales & Procurement, CRM, Accounting, Business Analytics, Human Resource
             </span>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-card
              class="py-12 px-4 rounded-lg"
              color="grey lighten-5"
              flat
              elevation="14"
              height="400"
          >
            <v-theme-provider dark>
              <div>
                <v-avatar
                    size="88"
                >
                  <v-img  src="../../assets/images/des-green-logo.png"></v-img>
                </v-avatar>
              </div>
            </v-theme-provider>

            <v-card-title
                class="justify-center font-weight-black"
            >
              Pembuatan Software Custom
            </v-card-title>

            <v-card-text
                class="subtitle-1"
            >
              Mengembangkan aplikasi custom berbasis web dan mobile dengan tampilan interaktif dan bisa menggunakan algoritma komputer cerdas
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-card
              class="py-12 px-4 rounded-lg"
              color="grey lighten-5"
              flat
              elevation="14"
              height="400"
          >
            <v-theme-provider dark>
              <div>
                <v-avatar
                    size="88"
                >
                  <v-img  src="../../assets/images/des-green-logo.png"></v-img>
                </v-avatar>
              </div>
            </v-theme-provider>

            <v-card-title
                class="justify-center font-weight-black"
            >
              Konsultan Sistem Informasi
            </v-card-title>

            <v-card-text
                class="subtitle-1"
            >
              Kami membelikan layanan konsultasi penerapan sistem yang aman diperusahaan,
              tidak hanya software tapi lebih mengenai alur dan prosedur dan penegakan hukum
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>

    </v-container>

    <div class="py-4"></div>
  </section>
</template>

<script>
export default {
  name: "LayananKami",
  data() {
    return {
      features: [
        {
          icon: 'mdi-account-group-outline',
          title: 'Panji Winson',
          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam',
        },
        {
          icon: 'mdi-update',
          title: 'Rina Putri',
          text: 'Sed ut elementum justo. Suspendisse non justo enim. Vestibulum cursus mauris dui, a luctus ex blandit. Lorem ipsum dolor sit amet consectetur adipisicing elit. qui ipsum eveniet facilis obcaecati corrupti consectetur adipisicing elit.',
        },
        {
          icon: 'mdi-shield-outline',
          title: 'M. Aliadit',
          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam',
        },
      ],
    }
  }
}
</script>

<style scoped>

</style>