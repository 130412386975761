import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FMaterialSalesBrandService {
    getApiUrl() {
        return API_URL
    }

    getAllFMaterialSalesBrand(){
        return axios.get(API_URL + `getAllFMaterialSalesBrand`, { headers: authHeader() });
    }
    getAllFMaterialSalesBrandContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFMaterialSalesBrand`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFMaterialSalesBrandContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFMaterialSalesBrandById(id){
        return axios.get(API_URL + `getFMaterialSalesBrandById/${id}`, { headers: authHeader() });
    }
    updateFMaterialSalesBrand(item){
        return axios.put(API_URL + `updateFMaterialSalesBrand/${item.id}`, item, {headers: authHeader()})
    }
    createFMaterialSalesBrand(item){
        return axios.post(API_URL + `createFMaterialSalesBrand`, item, {headers: authHeader()})
    }
    deleteFMaterialSalesBrand(id){
        return axios.delete(API_URL + `deleteFMaterialSalesBrand/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFMaterialSalesBrand(itemIds){
        return axios.delete(API_URL + `deleteAllFMaterialSalesBrand`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FMaterialSalesBrandService()