<template>
  <v-flex class="d-flex justify-center ma-4">
    <span>
      <v-avatar size="380">
        <v-img
            height="400"
            width="400"
            src="@/assets/images/aktor1.png"
        ></v-img>
      </v-avatar>
    </span>
  </v-flex>

</template>

<script>
export default {
  name: "TopRight"
}
</script>

<style scoped>

</style>