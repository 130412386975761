<template>
  <section id="features" class="blue lighten-4 pt-4">
    <div class="mx-10 my-4">
      <v-row>
        <v-col cols="12" sm="4" md="4" class="align-center justify-center">
          <v-layout column align-center>

            <v-card outlined elevation="5" class="rounded-lg icon--instagram icon">
              <v-icon x-large class="ma-4 ">mdi-thumb-up-outline</v-icon>
            </v-card>
          </v-layout>
          <v-layout column align-center>
            <div class="mt-2 font-weight-bold">
              Manajemen Reseller
            </div>
          </v-layout>
          <div class="d-flex align-center justify-center mt-4 caption" style="text-align: center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porttitor ut sit vulputate etiam elementum. Ut sed venenatis phar. .
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="4" class="align-center justify-center">
          <v-layout column align-center>

            <v-card outlined elevation="5" class="rounded-lg icon icon--twitter">
              <v-icon x-large class="ma-4">mdi-emoticon</v-icon>
            </v-card>

          </v-layout>
          <v-layout column align-center>
            <div class="mt-2 font-weight-bold" >
              Manajemen Penjualan
            </div>
          </v-layout>
          <div class="d-flex align-center justify-center mt-4 caption" style="text-align: center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porttitor ut sit vulputate etiam elementum. Ut sed venenatis phar. .
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="4" class="align-center justify-center">
          <v-layout column align-center>
            <v-card outlined elevation="5" class="rounded-lg  icon-tiktok icon">
              <v-icon x-large class="ma-4">mdi-chart-arc</v-icon>
            </v-card>


          </v-layout>
          <v-layout column align-center>
            <div class="mt-2 font-weight-bold">
              Fullfillment
            </div>
          </v-layout>
          <div class="d-flex align-center justify-center mt-4 caption" style="text-align: center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porttitor ut sit vulputate etiam elementum. Ut sed venenatis phar. .
          </div>
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="12" sm="4" md="4" class="align-center justify-center">
          <v-layout column align-center>
            <v-card outlined elevation="5" class="rounded-lg  icon--twitter icon">
              <v-icon x-large class="ma-4">mdi-badge-account-outline</v-icon>
            </v-card>

          </v-layout>
          <v-layout column align-center>
            <div class="mt-2 font-weight-bold">
              CRM
            </div>
          </v-layout>
          <div class="d-flex align-center justify-center mt-4 caption" style="text-align: center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porttitor ut sit vulputate etiam elementum. Ut sed venenatis phar. .
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="4" class="align-center justify-center">
          <v-layout column align-center>

            <v-card outlined elevation="5" class="rounded-lg  icon--instagram icon">
              <v-icon x-large class="ma-4">mdi-home</v-icon>
            </v-card>
          </v-layout>
          <v-layout column align-center>
            <div class="mt-2 font-weight-bold">
              WMS
            </div>
          </v-layout>
          <div class="d-flex align-center justify-center mt-4 caption" style="text-align: center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porttitor ut sit vulputate etiam elementum. Ut sed venenatis phar. .
          </div>
        </v-col>

        <v-col cols="12" sm="4" md="4" class="align-center justify-center">
          <v-layout column align-center>

            <v-card outlined elevation="5" class="rounded-lg  icon-tiktok icon">
              <v-icon x-large class="ma-4">mdi-truck-cargo-container</v-icon>
            </v-card>
          </v-layout>
          <v-layout column align-center>
            <div class="mt-2 font-weight-bold">
              Ekspedisi
            </div>
          </v-layout>
          <div class="d-flex align-center justify-center mt-4 caption" style="text-align: center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porttitor ut sit vulputate etiam elementum. Ut sed venenatis phar. .
          </div>
        </v-col>

      </v-row>

    </div>

  </section>
</template>

<script>
export default {
  name: "Section2",
  date() {
    return {
      features: [
        {
          icon: 'mdi-account-group-outline',
          title: 'Vibrant Community',
          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam',
        },
        {
          icon: 'mdi-update',
          title: 'Frequent Updates',
          text: 'Sed ut elementum justo. Suspendisse non justo enim. Vestibulum cursus mauris dui, a luctus ex blandit. Lorem ipsum dolor sit amet consectetur adipisicing elit. qui ipsum eveniet facilis obcaecati corrupti consectetur adipisicing elit.',
        },
        {
          icon: 'mdi-shield-outline',
          title: 'Long-term Support',
          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam',
        },
      ],
    }
  }
}
</script>

<style scoped>
.icons {
  display: flex;
  column-gap: 25px;
}

.icon {
  width: 70px;
  height: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 26px;
  color: #fff;
  border-radius: 20%;
  outline: 2px solid #fff;
  transition-property:
      outline-offset, outline-color,
      background-color;
  transition-duration: .25s;
}

.icon:hover {
  outline-offset: 5px;
}

.icon:hover i {
  animation: shake .25s;
}

.icon--instagram:hover {
  background-image: radial-gradient(circle at 30% 107%,
  #fdf497 0%, #fdf497 5%,
  #fd5949 45%, #d6249f 60%,
  #285AEB 90%);
  outline-color: #a02d76;
}

.icon--twitter:hover {
  background-color: #1da1f2;
  outline-color: #1da1f2;
}

.icon-tiktok:hover {
  background-image: radial-gradient(circle at 30% 107%,
  #9afd97 0%, #97fd99 5%,
  #fd5949 45%, #d6249f 60%,
  #35eb28 90%);
  outline-color: #a02d76;
}

.icon--github:hover {
  background-color: #2ea44f;
  outline-color: #2ea44f;
}

@keyframes shake {
  10% {
    transform: rotate(15deg);
  }

  20% {
    transform: rotate(-15deg);
  }

  30% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-15deg);
  }
}
</style>