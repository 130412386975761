<template>
  <v-flex >
    <v-card color="basil">
<!--      <v-card-title class="text-center justify-center py-2">-->
<!--          BASiL-->
<!--      </v-card-title>-->

      <v-tabs
          v-model="tab"
          background-color="transparent"
          color="primary"
          grow
      >
        <v-tab
            v-for="item in items"
            :key="item"
        >
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
            v-for="item in items"
            :key="item"
        >
          <v-card
              color="basil"
              flat
          >
            <v-card-text>{{ text }}</v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-flex>

</template>
<script>
export default {
  name: "Top",
  data () {
    return {
      tab: null,
      items: [
        'Pesanan', 'Produk', 'Stok', 'Chat', 'Gudang', 'Laporan',
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }
  },
};
</script>

<style scoped>
</style>
