<template>
  <div>
    <v-row align="center">
      <v-col cols="12" sm="6" md="6">
        <Layanan2Left></Layanan2Left>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <Layanan2Right></Layanan2Right>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Layanan2Left from "@/components/home/Layanan2Left";
import Layanan2Right from "@/components/home/Layanan2Right";
export default {
  name: "Layanan2",
  components: {
    Layanan2Right,
    Layanan2Left,
  }
}
</script>

<style scoped>

</style>