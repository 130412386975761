
const EStatusPengiriman = Object.freeze({
    WAIT_CONFIRM: 0,
    CONFIRMED: 1, //Confirmed lho
    /**
     * Mulai dihitung Omset Harian
     */
    ON_PACKING: 2, //dilakukan penandaan oleh Gudang bandung
    WAIT_PICKUP: 3,

    PICKED_UP: 4,
    ON_DELIVERY: 5,
    RECEIVED: 6,
    PAID: 7,

    COMPLAINED: 8,
    /**
     * Tidak dihitung Omset Harian
     */
    CANCELED: 9,//tidak dipakai => Cancel Order

    RETURN_PROCESS: 10,
    RETURN_LOCKED: 11,
    RETURN_COMPLETE: 12,

    INVALID_AWB: 401
})

const EStatusPengirimans= Object.freeze([
    {
        id: EStatusPengiriman.WAIT_CONFIRM,
        description: 'Menunggu Konfirmasi',
        shortDesc: 'Pending',
        color: 'grey',
        note: ' Pembayaran telah terverifikasi, menunggu Penjual menerima pesanan'
    },
    {
        id: EStatusPengiriman.CONFIRMED,
        description: 'Pesanan Dikonfirmasi',
        shortDesc: 'Confirmed',
        color: 'orange lighten-2',
        note: 'Penjual telah menerima pesanan, orderan kamu dalam tahap pengemasan, AWB Keluar'
    },

    {
        id: EStatusPengiriman.ON_PACKING,
        description: 'Sedang Proses Packing',
        shortDesc: 'On Process',
        color: 'orange darken-3',
        note: 'Cetak Resi, Gudang Menyiapkan Barang, Packing (SCAN PERTAMA)'
    },


    {
        id: EStatusPengiriman.WAIT_PICKUP,
        description: 'Menunggu Kurir',
        shortDesc: 'Wait Pickup',
        color: 'deep-orange darken-4',
        note: 'Pesanan sudah di validasi sebelum dibawa kurir (SCAN KEDUA -SERAH TERIMA)'
    },

    //WILAYAH EKSPEDISI
    {
        id: EStatusPengiriman.PICKED_UP,
        description: 'Pesanan Di-pickup/di-drop',
        shortDesc: 'Picked-Up',
        color: 'green lighten-2',
        note: 'Pesanan sudah di pickup oleh kurir dan menuju ke gerai & staging'
    },

    {
        id: EStatusPengiriman.ON_DELIVERY,
        description: 'Pesanan Dikirim',
        shortDesc: 'On Delivery',
        color: 'green darken-2',
        note: 'Pesanan kamu dalam pengiriman oleh jasa kurir'
    },

    /***
     *
     *
     *
     */
    {
        id: EStatusPengiriman.RECEIVED,
        description: 'Pesanan Tiba',
        shortDesc: 'RECEIVED',
        color: 'blue darken-1',
        note: 'Pesanan kamu telah sampai alamat tujuan, dan dana akan diteruskan ke penjual'
    },
    {
        id: EStatusPengiriman.PAID,
        description: 'Pesanan Selesai (dibayar oleh/ke ekspedisi)',
        shortDesc: 'PAID',
        color: 'indigo darken-1',
        note: 'Dana rekonsiliasi sudah dibayarkan oleh ekspedisi/MP'
    },
    {
        id: EStatusPengiriman.COMPLAINED,
        description: 'Pesanan Dikomplain/Gagal Kirim',
        shortDesc: 'Gagal Kirim',
        color: 'red lighten-3',
        note: 'Pesanan kamu mengalami masalah dan kamu telah mengajukan komplain'
    },

    /**
     * PEMBATALAN ORDER sebelum dikirim
     */
    {
        id: EStatusPengiriman.CANCELED,
        description: 'Pesanan dibatalkan(sebelum dikirim)',
        shortDesc: 'CANCELLED',
        color: 'red darken-1',
        notes: 'Pesanan kamu telah dibatalkan penjual karena suatu alasan'
    },
    /**
     * ========
     */

    {
        id: EStatusPengiriman.RETURN_PROCESS,
        description: 'Proses Pengembalian',
        shortDesc: 'Proses Retur',
        color: 'red lighten-1',
        notes: 'Pesanan sedang dalam proses pengembalian ke penjual'
    },
    {
        id: EStatusPengiriman.RETURN_LOCKED,
        description: 'Pengembalian Terkunci',
        shortDesc: 'Retur Terkunci',
        color: 'red darken-4',
        notes: 'Proses pengembalian fix dari ekpedisi dan barang dalam perjalanan ke penjual'
    },
    {
        id: EStatusPengiriman.RETURN_COMPLETE,
        description: 'Barang diterima gudang',
        shortDesc: 'Retur Selesai',
        color: 'teal darken-5',
        notes: 'Barang sudah diterima oleh gudang asal'
    },

    {
        id: EStatusPengiriman.INVALID_AWB,
        description: 'Invalid AWB',
        shortDesc: 'INVALID-AWB',
        color: 'red darken-4',
        notes: 'AWB Invalid'
    }

])

export  {EStatusPengiriman as default , EStatusPengirimans}