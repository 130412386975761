export const sales = {
  namespaced: true,
  state: {
    formMode: '',
    formDetailMode: '',

    lastFdivisionBean : 0,
    lastFsalesmanBean : 0,
    lastFstoreBean: 0,

    currentDistributionChannel: undefined,
    itemsFTax: [],
  },
  actions: {
    setDistributionChannel({commit}, fdistributionChannel){
      commit('mutateDistributionChannel', fdistributionChannel)
    },

    updateFormMode({commit}, payload){
      commit('mutateFormMode', payload)
    },
    updateFormDetailMode({commit}, payload){
      commit('mutateFormDetailMode', payload)
    },

    updateLastFdivisionBean({commit}, payload){
      commit('mutateLastFdivisionBean', payload)
    },
    updateLastFsalesmanBean({commit}, payload){
      commit('mutateLastFsalesmanBean', payload)
    },
    updateLastFstoreBean({commit}, payload){
      commit('mutateLastFstoreBean', payload)
    },

    reloadItemsFTax({commit}, payload){
      commit('reloadItemsFTax', payload)
    },

  },
  mutations: {
    mutateDistributionChannel(state, fdistributionChannel){
      state.currentDistributionChannel = fdistributionChannel
    },

    mutateFormMode(state, payLoad){
      state.formMode = payLoad
    },
    mutateFormDetailMode(state, payLoad){
      state.formDetailMode = payLoad
    },

    mutateLastFdivisionBean(state, payLoad){
      state.lastFdivisionBean = payLoad
    },
    mutateLastFsalesmanBean(state, payLoad){
      state.lastFsalesmanBean = payLoad
    },
    mutateLastFstoreBean(state, payLoad){
      state.lastFstoreBean = payLoad
    },

    reloadItemsFTax(state, payLoad){
      state.itemsFTax = payLoad
    },

  }

};
