import FDivisionService from "../services/apiservices/f-division-service";
import FCustomerService from '../services/apiservices/f-customer-service';
import FMaterialService from '../services/apiservices/f-material-service';
import FExpedisiService from "../services/apiservices/f-expedisi-service";
import FWarehouseService from "../services/apiservices/f-warehouse-service"
import FSalesmanService from "../services/apiservices/f-salesman-service"
import FPayMethodService from "../services/apiservices/f-pay-method-service"
import FStoreService from "../services/apiservices/f-store-service"

import FSubAreaService from "../services/apiservices/f-sub-area-service"
import FAreaService from "../services/apiservices/f-area-service"
import FRegionService from "../services/apiservices/f-region-service"
import FDistributionChannelService from "@/services/apiservices/f-distribution-channel-service";
import FCustomerGroupService from "@/services/apiservices/f-customer-group-service";
import FMaterialGroup2Service from "@/services/apiservices/f-material-group2-service";
import FMaterialGroup3Service from "@/services/apiservices/f-material-group3-service";
import FMaterialGroup1Service from "@/services/apiservices/f-material-group1-service";
import FMaterialSalesBrandService from "@/services/apiservices/f-material-sales-brand-service";
import FVendorService from "@/services/apiservices/f-vendor-service";
import FWarehouseExpedisiService from "@/services/apiservices/f-warehouse-expedisi-service";

export const data = {
  namespaced: true,
  state: {
    listFDivision:[],
    listFCustomer:[],

    listFExpedisi:[],
    listFWarehouse:[],
    listFSalesman:[],
    listFPayMethod:[],
    listFStore:[],
    listFSubArea:[],
    listFArea:[],
    listFRegion:[],

    listFDistributionChannel:[],
    listFCustomerGroup:[],

      listFMaterial:[],
      listFMaterialGroup3:[],
      listFMaterialGroup2:[],
      listFMaterialGroup1:[],
      listFMaterialSalesBrand:[],
      listFVendor:[],

      listFWarehouseExpedisiAvailable: [],


  },

  actions: {
    loadFDivisionByOrgLevel({commit}){
        FDivisionService.getAllFDivisionByOrgLevel().then(
            response =>{
              commit('loadFDivisionByOrgLevelSuccess', response.data)
            },
            error =>{
              commit('loadFDivisionByOrgLevelFailure')
              return Promise.reject(error)
            }
        )
    },

    loadFDivision({commit}){
      FDivisionService.getAllFDivisionByOrgLevel().then(
          response =>{
              commit('loadFDivisionSuccess', response.data)
          },
          error =>{
              commit('loadFDivisionFailure')
              return Promise.reject(error)
          }
      )
    },
    loadFCustomer({commit}, payload){
        let fsalesmanBean = payload.fsalesmanBean

        if (fsalesmanBean > 0){

            // FCustomerService.getAllFCustomerBySalesman(fsalesmanBean).then(
            //     response =>{
            //         commit('loadFCustomerSuccess', response.data)
            //     },
            //     error =>{
            //         commit('loadFCustomerFailure')
            //         return Promise.reject(error)
            //     }
            // )

            FCustomerService.getAllFCustomerBySalesmanAndCustnameContaining(fsalesmanBean, "").then(
                response =>{
                    commit('loadFCustomerSuccess', response.data)
                },
                error =>{
                    commit('loadFCustomerFailure')
                    return Promise.reject(error)
                }
            )

        }else {
            FCustomerService.getAllFCustomerSimple().then(
                response =>{
                    commit('loadFCustomerSuccess', response.data)
                },
                error =>{
                    commit('loadFCustomerFailure')
                    return Promise.reject(error)
                }
            )
        }

    },
    loadFExpedisi({commit}){
      FExpedisiService.getAllFExpedisiByCompany().then(
          response =>{
            commit('loadFExpedisiSuccess', response.data)
          },
          error =>{
            commit('loadFExpedisiFailure')
            return Promise.reject(error)
          }
      )
    },
    loadFWarehouse({commit}){
      FWarehouseService.getAllFWarehouseByCompany().then(
          response =>{
            commit('loadFWarehouseSuccess', response.data)
          },
          error =>{
            commit('loadFWarehouseFailure')
            return Promise.reject(error)
          }
      )
    },
    loadFSalesman({commit}){
      FSalesmanService.getAllFSalesmanSimple().then(
          response =>{
            commit('loadFSalesmanSuccess', response.data)
          },
          error =>{
            commit('loadFSalesmanFailure')
            return Promise.reject(error)
          }
      )
    },
  loadFSalesmanChildrenAndMe({commit}){
      FSalesmanService.getAllMyAgenChildrenAndMe().then(
          response =>{
              commit('loadFSalesmanSuccess', response.data)
          },
          error =>{
              commit('loadFSalesmanFailure')
              return Promise.reject(error)
          }
      )
  },

    loadFPayMethod({commit}){
      FPayMethodService.getAllFPayMethodByCompany().then(
          response =>{
            commit('loadFPayMethodSuccess', response.data)
          },
          error =>{
            commit('loadFPayMethodFailure')
            return Promise.reject(error)
          }
      )
    },
    loadFStore({commit}){
        FStoreService.getAllFStoreSimple().then(
          response =>{

              commit('loadFStoreSuccess', response.data)
          },
          error =>{
              commit('loadFStoreFailure')
              return Promise.reject(error)
          }
      )
    },
    loadFSubArea({commit}){
      FSubAreaService.getAllFSubAreaSimple().then(
          response =>{
              commit('loadFSubAreaSuccess', response.data)
          },
          error =>{
              commit('loadFSubAreaFailure')
              return Promise.reject(error)
          }
      )
    },
    loadFArea({commit}){
      FAreaService.getAllFAreaSimple().then(
          response =>{
              commit('loadFAreaSuccess', response.data)
          },
          error =>{
              commit('loadFAreaFailure')
              return Promise.reject(error)
          }
      )
    },
    loadFRegion({commit}){
      FRegionService.getAllFRegion().then(
          response =>{
              commit('loadFRegionSuccess', response.data)
          },
          error =>{
              commit('loadFRegionFailure')
              return Promise.reject(error)
          }
      )
    },

    loadFDistributionChannel({commit}){
      FDistributionChannelService.getAllFDistributionChannelByCompanySimple().then(
          response =>{
              commit('loadFDistributionChannelSuccess', response.data)
          },
          error =>{
              commit('loadFDistributionChannelFailure')
              return Promise.reject(error)
          }
      )
    },
    loadFCustomerGroup({commit}){
      FCustomerGroupService.getAllFCustomerGroupByCompanySimple().then(
          response =>{
              commit('loadFCustomerGroupSuccess', response.data)
          },
          error =>{
              commit('loadFCustomerGroupFailure')
              return Promise.reject(error)
          }
      )
    },



      loadFMaterial({commit}){
          FMaterialService.getAllFMaterial().then(
              response =>{
                  commit('loadFMaterialSuccess', response.data)
              },
              error =>{
                  commit('loadFMaterialFailure')
                  return Promise.reject(error)
              }
          )
      },
      loadFMaterialGroup3({commit}){
          FMaterialGroup3Service.getAllFMaterialGroup3().then(
              response =>{
                  commit('loadFMaterialGroup3Success', response.data)
              },
              error =>{
                  commit('loadFMaterialGroup3Failure')
                  return Promise.reject(error)
              }
          )
      },
      loadFMaterialGroup2({commit}){
          FMaterialGroup2Service.getAllFMaterialGroup2().then(
              response =>{
                  commit('loadFMaterialGroup2Success', response.data)
              },
              error =>{
                  commit('loadFMaterialGroup2Failure')
                  return Promise.reject(error)
              }
          )
      },
      loadFMaterialGroup1({commit}){
          FMaterialGroup1Service.getAllFMaterialGroup1().then(
              response =>{
                  commit('loadFMaterialGroup1Success', response.data)
              },
              error =>{
                  commit('loadFMaterialGroup1Failure')
                  return Promise.reject(error)
              }
          )
      },
      loadFMaterialSalesBrand({commit}){
          FMaterialSalesBrandService.getAllFMaterialSalesBrand().then(
              response =>{
                  commit('loadFMaterialSalesBrandSuccess', response.data)
              },
              error =>{
                  commit('loadFMaterialSalesBrandFailure')
                  return Promise.reject(error)
              }
          )
      },
      loadFVendor({commit}){
          FVendorService.getAllFVendor().then(
              response =>{
                  commit('loadFVendorSuccess', response.data)
              },
              error =>{
                  commit('loadFVendorFailure')
                  return Promise.reject(error)
              }
          )
      },

      loadFWarehouseExpedisiAvailable({commit}){
          FWarehouseExpedisiService.getAllFWarehouseExpedisiAvailable().then(
              response =>{

                  // console.log(JSON.stringify(response.data))

                  commit('loadFWarehouseExpedisiAvailableSuccess', response.data)
              },
              error =>{
                  commit('loadFWarehouseExpedisiAvailableFailure')
                  return Promise.reject(error)
              }
          )
      },


  },
  mutations: {
      loadFDivisionByOrgLevelSuccess(state, items) {
          state.listFDivision = items;
      },
      loadFDivisionByOrgLevelFailure(state) {
          state.listFDivision = []
      },

    loadFDivisionSuccess(state, items) {
      state.listFDivision = items;
    },
    loadFDivisionFailure(state) {
      state.listFDivision = []
    },
    loadFCustomerSuccess(state, items) {
      state.listFCustomer = items;
    },
    loadFCustomerFailure(state) {
      state.listFCustomer = []
    },
    loadFExpedisiSuccess(state, items) {
      state.listFExpedisi = items;
    },
    loadFExpedisiFailure(state) {
      state.listFExpedisi = []
    },
    loadFWarehouseSuccess(state, items) {
      state.listFWarehouse = items;
    },
    loadFWarehouseFailure(state) {
      state.listFWarehouse = []
    },
    loadFSalesmanSuccess(state, items) {
      state.listFSalesman = items;
    },
    loadFSalesmanFailure(state) {
      state.listFSalesman = []
    },
    loadFPayMethodSuccess(state, items) {
      state.listFPayMethod = items;
    },
    loadFPayMethodFailure(state) {
      state.listFPayMethod = []
    },
    loadFStoreSuccess(state, items) {
      state.listFStore = items;
    },
    loadFStoreFailure(state) {
      state.listFStore = []
    },

    loadFSubAreaSuccess(state, items) {
      state.listFSubArea = items;
    },
    loadFSubAreaFailure(state) {
      state.listFSubArea = []
    },

      loadFAreaSuccess(state, items) {
          state.listFArea = items;
      },
      loadFAreaFailure(state) {
          state.listFArea = []
      },
      loadFRegionSuccess(state, items) {
          state.listFRegion = items;
      },
      loadFRegionFailure(state) {
          state.listFRegion = []
      },

      loadFDistributionChannelSuccess(state, items) {
          state.listFDistributionChannel = items;
      },
      loadFDistributionChannelFailure(state) {
          state.listFDistributionChannel = []
      },
      loadFCustomerGroupSuccess(state, items) {
          state.listFCustomerGroup = items;
      },
      loadFCustomerGroupFailure(state) {
          state.listFCustomerGroup = []
      },


      loadFMaterialSuccess(state, items) {
          state.listFMaterial = items;
      },
      loadFMaterialFailure(state) {
          state.listFMaterial = []
      },
      loadFMaterialGroup3Success(state, items) {
          state.listFMaterialGroup3 = items;
      },
      loadFMaterialGroup3Failure(state) {
          state.listFMaterialGroup3 = []
      },
      loadFMaterialGroup2Success(state, items) {
          state.listFMaterialGroup2 = items;
      },
      loadFMaterialGroup2Failure(state) {
          state.listFMaterialGroup2 = []
      },
      loadFMaterialGroup1Success(state, items) {
          state.listFMaterialGroup1 = items;
      },
      loadFMaterialGroup1Failure(state) {
          state.listFMaterialGroup1 = []
      },
      loadFMaterialSalesBrandSuccess(state, items) {
          state.listFMaterialSalesBrand = items;
      },
      loadFMaterialSalesBrandFailure(state) {
          state.listFMaterialSalesBrand = []
      },
      loadFVendorSuccess(state, items) {
          state.listFVendor = items;
      },
      loadFVendorFailure(state) {
          state.listFVendor = []
      },


      loadFWarehouseExpedisiAvailableSuccess(state, items) {
          state.listFWarehouseExpedisiAvailable = items;
      },
      loadFWarehouseExpedisiAvailableFailure(state) {
          state.listFWarehouseExpedisiAvailable = []
      },


  }
};
