<template>
  <v-app id="app" class="grey lighten-4">

    <NavDrawer v-if="currentUser!==null"/>
    <MenuBar  v-if="currentUser===null"/>

    <v-main>
      <router-view></router-view>
    </v-main>

    <!--    <v-content transition="slide-x-transition">-->
    <!--      <router-view></router-view>-->
    <!--    </v-content>-->

  </v-app>
</template>



<script>
import NavDrawer from './components/NavDrawer'
import MenuBar from "./components/MenuBar";

export default {
  components: {MenuBar: MenuBar, NavDrawer},
  name: 'App',
  computed: {
    currentUser() {
      // console.log( `Isinya ${this.$store.state.auth.user}` )
      return this.$store.state.auth.user;
    },

  },
  mounted() {
    if (!this.currentUser) {
      //Kita tidak akan pernah push apapun jika belum login
      // this.$router.push('/login');
    }else {

      this.$store.dispatch('data/loadFDivision')

      // this.$store.dispatch('data/loadFSalesman')
      // this.$store.dispatch('data/loadFMaterial')
      // this.$store.dispatch('data/loadFStore')
      // this.$store.dispatch('data/loadFWarehouse')
      // this.$store.dispatch('data/loadFExpedisi')
      //
      // this.$store.dispatch('data/loadFRegion')
      // this.$store.dispatch('data/loadFArea')
      // this.$store.dispatch('data/loadFSubArea')

    }

    /**
     * AKAN DI BERIKAN VALIDATE EXPIRED TOKEN
     */
    // console.log(`Current user is ${JSON.stringify(this.currentUser)}`)
    console.log("###### MOUNTED App.Vue PERTAMA KALI ####")
    // TestService.getAdminBoard().then(
    //     ()=>{
    //     },
    //     error=>{
    //       console.log(`${error.response.status}`)
    //       if (error.response.status===401){
    //         this.$store.dispatch('auth/logout');
    //         // this.$router.push('/login'); //tidak diarahkan kemana mana donk
    //
    //       }
    //     }
    // )

  }

}

</script>

<style>

  .color-gradient-1 {
    background-image: linear-gradient(to top left, #f5c874 10%, transparent 90%);
  }
  .color-gradient-2 {
    background-image: linear-gradient(to top left, #f5c874 10%, transparent 90%);
  }
  .color-gradient-3 {
    background-image: linear-gradient(to top left, #f5c874 5%, transparent 45%);
  }

  .color-top-gradient-3 {
    background-image: linear-gradient(to bottom right, #e8d8bf 5%, transparent 45%);
  }
  .color-top-left-gradient-3 {
    background-image: linear-gradient(to bottom left, #f5ebd7 5%, transparent 45%);
  }



  .color-gradient-red-1 {
    background-image: linear-gradient(to top left, #de8b8b 10%, transparent 90%);
  }
  .color-gradient-red-2 {
    background-image: linear-gradient(to top left, #e3b1b1 10%, transparent 90%);
  }
  .color-gradient-red-3 {
    background-image: linear-gradient(to top left, #eccfcf 5%, transparent 45%);
  }

  .color-top-gradient-red-3 {
    background-image: linear-gradient(to bottom left, #eccfcf 5%, transparent 45%);
  }


  .bottom-gradient {
    background-image: linear-gradient(to top left, #cc9c35 10%, transparent 90%);
  }


  .repeating-gradient {
    background-image: repeating-linear-gradient(-45deg,
    rgba(255,0,0,.25),
    rgba(255,0,0,.25) 5px,
    rgba(0,0,255,.25) 5px,
    rgba(134, 134, 234, 0.25) 10px
    );
  }
  .navbar_submenu_color{
    background-color: #ead0a9;
  }


</style>
