const ConstApiUrls = ({

    // AUTH_SERVICE_URL: 'http://localhost:8787/api/auth/',
    // TEST_SERVICE_URL: 'http://localhost:8787/api/test/',
    // API_SERVICE_URL: 'http://localhost:8787/api/desgreen/'

    // AUTH_SERVICE_URL: 'https://siati.pevesindo.co.id:8787/api/auth/',
    // TEST_SERVICE_URL: 'https://siati.pevesindo.co.id:8787/api/test/',
    // API_SERVICE_URL: 'https://siati.pevesindo.co.id:8787/api/desgreen/'


    AUTH_SERVICE_URL: 'http://siati.pevesindo.co.id:8787/api/auth/',
    TEST_SERVICE_URL: 'http://siati.pevesindo.co.id:8787/api/test/',
    API_SERVICE_URL: 'http://siati.pevesindo.co.id:8787/api/desgreen/'




    /**
     * TEST TRIAL
     * VPS DES
     */
    // AUTH_SERVICE_URL: 'http://des-green.com:8787/api/auth/',
    // TEST_SERVICE_URL: 'http://des-green.com:8787/api/test/',
    // API_SERVICE_URL: 'http://des-green.com:8787/api/desgreen/'

})
export default ConstApiUrls
