import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FWarehouseExpedisiService {
    getApiUrl() {
        return API_URL
    }
    getAllFWarehouseExpedisi(){
        return axios.get(API_URL + `getAllFWarehouseExpedisi`, { headers: authHeader() });
    }

    getAllFWarehouseExpedisiAvailable(){
        return axios.get(API_URL + `getAllFWarehouseExpedisiAvailable`, { headers: authHeader() });
    }

    getAllFWarehouseExpedisiContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFWarehouseExpedisi`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFWarehouseExpedisiContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFWarehouseExpedisiById(id){
        return axios.get(API_URL + `getFWarehouseExpedisiById/${id}`, { headers: authHeader() });
    }

    getAllByFwarehouseBean(fwarehouseBean){
        return axios.get(API_URL + `getAllByFwarehouseBean/${fwarehouseBean}`, { headers: authHeader() });
    }


    getAllByFexpedisiBean(fexpedisiBean){
        return axios.get(API_URL + `getAllByFexpedisiBean/${fexpedisiBean}`, { headers: authHeader() });
    }
    getAllByFwarehouseBeanAndFexpedisiBean(fwarehouseBean, fexpedisiBean){
        return axios.get(API_URL + `getAllByFwarehouseBeanAndFexpedisiBean/${fwarehouseBean}/${fexpedisiBean}`, { headers: authHeader() });
    }


    updateFWarehouseExpedisi(item){
        return axios.put(API_URL + `updateFWarehouseExpedisi/${item.id}`, item, {headers: authHeader()})
    }
    createFWarehouseExpedisi(item){
        return axios.post(API_URL + `createFWarehouseExpedisi`, item, {headers: authHeader()})
    }
    deleteFWarehouseExpedisi(id){
        return axios.delete(API_URL + `deleteFWarehouseExpedisi/${id}`, {
            headers: authHeader()
        });
    }

    deleteFWarehouseExpedisiAvatarByParent(fkegiatanBean){
        return axios.delete(API_URL + `deleteFWarehouseExpedisiAvatarByParent/${fkegiatanBean}`, {
            headers: authHeader()
        });
    }
    createFWarehouseExpedisiAvatar(item){
        return axios.post(API_URL + `createFWarehouseExpedisiAvatar`, item, {headers: authHeader()})
    }

    deleteAllFWarehouseExpedisi(itemIds){

        return axios.delete(API_URL + `deleteAllFWarehouseExpedisi`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FWarehouseExpedisiService()