import FtSaleshFilter from "@/models/payload/ft-salesh-filter";

export const filter = {
  namespaced: true,
  state: {
    ftSaleshFilter: new FtSaleshFilter(),

    filterOrderDateFrom: '',
    filterOrderDateTo: '',

    filterFdivisions: [],
    filterFsalesmans: [],
    filterFstores: [],
    filterFexpedisies:[],
    filterFmaterials: [],
    filterEstatusPengiriman: [],
    filterFwarehouses: [],

    filterByGroupMaterialOrdered:[],
    itemsGroupMaterialOrdered:[],

  },
  actions: {
    ftSaleshFilterUpdate({commit}, payload){
      commit('ftSaleshFilterMutate', payload)
    },

    filterOrderDateFromUpdate({commit}, payload){
      commit('filterOrderDateFromMutate', payload)
    },
    filterOrderDateToUpdate({commit}, payload){
      commit('filterOrderDateToMutate', payload)
    },

    filterFdivisionsUpdate({commit}, payload){
      commit('filterFdivisionsMutate', payload)
    },
    filterFsalesmansUpdate({commit}, payload){
      commit('filterFsalesmansMutate', payload)
    },
    filterFstoresUpdate({commit}, payload){
      commit('filterFstoresMutate', payload)
    },
    filterFexpedisiesUpdate({commit}, payload){
      commit('filterFexpedisiesMutate', payload)
    },
    filterFmaterialsUpdate({commit}, payload){
      commit('filterFmaterialsMutate', payload)
    },
    filterEstatusPengirimanUpdate({commit}, payload){
      commit('filterEstatusPengirimanMutate', payload)
    },
    filterFwarehousesUpdate({commit}, payload){
      commit('filterFwarehousesMutate', payload)
    },

    filterByGroupMaterialOrderedUpdate({commit}, payload){
      commit('filterByGroupMaterialOrderedMutate', payload)
    },
    itemsGroupMaterialOrderedUpdate({commit}, payload){
      commit('itemsGroupMaterialOrderedMutate', payload)
    },


  },
  mutations: {
    ftSaleshFilterMutate(state, payload){
      state.ftSaleshFilter = payload
    },
    filterOrderDateFromMutate(state, payLoad){
      state.filterOrderDateFrom = payLoad
    },
    filterOrderDateToMutate(state, payLoad){
      state.filterOrderDateTo = payLoad
    },

    filterFdivisionsMutate(state, payLoad){
      state.filterFdivisions = payLoad
    },
    filterFsalesmansMutate(state, payLoad){
      state.filterFsalesmans = payLoad
    },
    filterFstoresMutate(state, payLoad){
      state.filterFstores = payLoad
    },
    filterFexpedisiesMutate(state, payLoad){
      state.filterFexpedisies = payLoad
    },
    filterFmaterialsUpdate(state, payLoad){
      state.filterFmaterials = payLoad
    },
    filterEstatusPengirimanMutate(state, payLoad){
      state.filterEstatusPengiriman = payLoad
    },
    filterFwarehousesMutate(state, payLoad){
      state.filterFwarehouses = payLoad
    },

    filterByGroupMaterialOrderedMutate(state, payLoad){
      state.filterByGroupMaterialOrdered = payLoad
    },
    itemsGroupMaterialOrderedMutate(state, payLoad){
      state.itemsGroupMaterialOrdered = payLoad
    },

  }

};
