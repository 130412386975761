<template>
  <section id="price" style="background-color: #F5F5F5">
    <div class="py-12"></div>

    <v-container>
      <h2 class="display-2 font-weight-bold mb-10 text-center">Pricing Plan</h2>
      <v-row>

        <v-col
            cols="12"
            md="4"
            sm="4"
        >
          <v-card tile class="rounded-lg">
            <v-card-title class="justify-center">
              <v-icon large>
                mdi-account
              </v-icon>
            </v-card-title>
            <v-card-text class="subtitle-1 font-weight-bold">
              <div>
                Management Reseller
              </div>
              <div>
                Fulfillment
              </div>
              CRM
              <div>
                WMS
              </div>
              <div>
                Mitra Ekspedisi
              </div>
              <div>
                Laporan Keuangan
              </div>
              <div class="text-center">{{ price }}</div>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn depressed rounded color="success">
                Free
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-col>
        <v-col
            cols="12"
            md="4"
            sm="4"
        >
          <v-card tile class="rounded-lg">
            <v-card-title class="justify-center">
              <v-icon large>
                mdi-account
              </v-icon>
            </v-card-title>
            <v-card-text class="subtitle-1 font-weight-bold">
              <div>
                Management Reseller
              </div>
              <div>
                Fulfillment
              </div>
              CRM
              <div>
                WMS
              </div>
              <div>
                Mitra Ekspedisi
              </div>
              <div>
                Laporan Keuangan
              </div>
              <div class="text-center">{{ price }}</div>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn depressed rounded color="success">
                Free
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-col>
        <v-col
            cols="12"
            md="4"
            sm="4"
        >
          <v-card tile class="rounded-lg">
            <v-card-title class="justify-center">
              <v-icon large>
                mdi-account
              </v-icon>
            </v-card-title>
            <v-card-text class="subtitle-1 font-weight-bold">
              <div>
                Management Reseller
              </div>
              <div>
                Fulfillment
              </div>
              CRM
              <div>
                WMS
              </div>
              <div>
                Mitra Ekspedisi
              </div>
              <div>
                Laporan Keuangan
              </div>
              <div class="text-center">{{ price }}</div>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn depressed rounded color="success">
                Free
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-col>

      </v-row>
    </v-container>

    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
  name: "Price",
  data() {
    return {

    }
  }
}
</script>

<style scoped>

</style>