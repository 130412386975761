<template>
  <v-flex class="d-flex justify-center">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card class="ma-10" shaped color="rgb(255, 0, 0, 0.2)" :elevation="hover? 16:7" >
          <v-img
              class="rounded-lg"
              height="350"
              width="350"
              src="@/assets/images/image_cs.jpeg"
          >
          </v-img>
        </v-card>
      </template>
    </v-hover>

  </v-flex>
</template>

<script>
export default {
  name: "TopRight"
}
</script>

<style scoped>

</style>