const EOtherStatus = Object.freeze({
    OPEN: 0,
    REQ_DISCOUNT: 1,
    REQ_PLAFON: 2,
    COMPLETE: 10,

})

const EOtherStatuses = Object.freeze([
    {
        id: EOtherStatus.OPEN,
        decription: 'Open'
    },
    {
        id: EOtherStatus.REQ_DISCOUNT,
        description: 'Request Discount to Supervisor'
    },
    {
        id: EOtherStatus.REQ_PLAFON,
        description: 'Request Plafon Credit Limit'
    },

    {
        id: EOtherStatus.COMPLETE,
        description: 'Complete Request and Close'
    }

])
export  {EOtherStatus as default, EOtherStatuses }