export default class FMaterialMap {
  constructor(
      id,

      fmaterialBean =0,

      toMaterialBean =0,
      toQty =1,

      created,
      modifiedBy
  ) {
    this.id = id;

    this.fmaterialBean = fmaterialBean;

    this.toMaterialBean = toMaterialBean;
    this.toQty = toQty;

    this.created = created;
    this.modifiedBy = modifiedBy;
  }

}
