<template>
<section style="background-color: #1518b0">
  <div class="py-12"></div>
    <v-container class="text-center">
      <h2 class="white--text display-2 font-weight-bold mb-3">Kelola Reseller Anda dan tingkatkan 10x lipat penjualan dalam sekejap</h2>
      <div class="mt-4">
        <v-btn depressed color="success">Coba Sekarang</v-btn>
        <v-btn outlined color="success" class="ml-2">Ikuti Demo</v-btn>
      </div>
    </v-container>

  <div class="py-12"></div>
</section>
</template>

<script>
export default {
  name: "Kelola"
}
</script>

<style scoped>

</style>