export default class FPromotionRulesdValidCusts {
  constructor(
      id,
      fpromotionRuleshBean=0,

      validCustomerBean=0,
      validAreaBean=0,
      validRegionBean=0,
      validCustomerGroupBean=0,
      validDistributionChannelBean=0,

      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.fpromotionRuleshBean = fpromotionRuleshBean;


    this.validCustomerBean = validCustomerBean;
    this.validAreaBean = validAreaBean;
    this.validRegionBean = validRegionBean;
    this.validCustomerGroupBean = validCustomerGroupBean;
    this.validDistributionChannelBean = validDistributionChannelBean;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }
}
