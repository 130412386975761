import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FStoreService {
    getApiUrl() {
        return API_URL
    }

    getAllFStore(){
        return axios.get(API_URL + `getAllFStore`, { headers: authHeader() });
    }
    getAllFStoreSimple(){
        return axios.get(API_URL + `getAllFStoreSimple`, { headers: authHeader() });
    }
    getAllFStoreContaining(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `getAllFStoreContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getAllFStoreContainingAndFsalesmanBean(page, pageSize, sortBy, order, search, fsalesmanBean){
        return axios.get(API_URL + `getAllFStoreContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}&fsalesmanBean=${fsalesmanBean}`, { headers: authHeader() });
    }
    getFStoreById(id){
        return axios.get(API_URL + `getFStoreById/${id}`, { headers: authHeader() });
    }
    getAllFStoreByDescription(description){
        return axios.get(API_URL + `getAllFStoreByDescription/${description}`, { headers: authHeader() });
    }

    updateFStore(item){
        return axios.put(API_URL + `updateFStore/${item.id}`, item, {headers: authHeader()})
    }
    createFStore(item){
        return axios.post(API_URL + `createFStore`, item, {headers: authHeader()})
    }
    deleteFStore(id){
        return axios.delete(API_URL + `deleteFStore/${id}`, {
            headers: authHeader()
        });
    }


    deleteAllFStore(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFStore`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFStore`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FStoreService()