<template>
  <section id="trust" style="background-color: #9CB5A7 ">
      <div class="py-8"></div>
    <v-container class="text-center">
      <h2 class="white--text display-1 font-weight-bold mb-3">Telah dipercaya oleh Distributor dan Pabrik Dalam Negeri</h2>
      <v-row class="text-center mt-10 align-center justify-center">
        <v-col
            cols="4"
            sm="2"
            md="2">
            <v-img
                class="elevation-14"
                max-height="200"
                max-width="200"
                src="@/assets/images/dpmptsp-jatim-logo.png"
            ></v-img>
        </v-col>
        <v-col
            cols="4"
            sm="2"
            md="2">
          <v-img
              class="elevation-14"
              max-height="200"
              max-width="200"
              src="@/assets/images/lapisblitar-logo.jpeg"
          ></v-img>
        </v-col>
        <v-col
            cols="4"
            sm="2"
            md="2">
          <v-img
              class="elevation-14"
              max-height="200"
              max-width="200"
              src="@/assets/images/pevesindo-logo.jpg"
          ></v-img>
        </v-col>
        <v-col
            cols="4"
            sm="2"
            md="2">
          <v-img
              class="elevation-14"
              max-height="200"
              max-width="200"
              src="@/assets/images/saerah-logo.jpeg"
          ></v-img>
        </v-col>

        <v-col
            cols="4"
            sm="2"
            md="2">
          <v-img
              class="elevation-14"
              src="@/assets/images/sastro-logo.png"
          ></v-img>
        </v-col>
        <v-col
            cols="4"
            sm="2"
            md="2">
          <v-img
              class="elevation-14"
              max-height="200"
              max-width="200"
              src="@/assets/images/logo-landahur.png"
          ></v-img>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col class="subtitle-1 font-weight-bold red--text">
          *Dan banyak lagi perusahaan swasta maupun pemerintahan yang menggunakan produk kami
        </v-col>
      </v-row>
    </v-container>
      <div class="py-8"></div>
  </section>
</template>

<script>
export default {
  name: "Trust"
}
</script>

<style scoped>

</style>