import FPromotionRulesh from "@/models/f-promotion-rulesh";
import FPromotionRulesdValidCusts from "@/models/f-promotion-rulesd-valid-custs";
import FPromotionRulesdValidProducts from "@/models/f-promotion-rulesd-valid-products";
import FPromotionRulesdValidCustsService from "@/services/apiservices/f-promotion-rulesd-valid-custs-service";
import FPromotionRulesdValidProductsService from "@/services/apiservices/f-promotion-rulesd-valid-products-service";

export const aktifitaspromo = {
  namespaced: true,
  state: {
    itemModified: new FPromotionRulesh(),

    itemValidCust : new FPromotionRulesdValidCusts(),
    itemsValidCusts: [],

    itemValidProduct: new FPromotionRulesdValidProducts(),
    itemsValidProducts: []
  },
  actions: {
    updateItemModified({commit}, payload){
      commit('mutateItemModified', payload)
    },
    updateItemValidCust({commit}, payload){
      commit('mutateItemValidCust', payload)
    },
    updateItemValidProduct({commit}, payload){
      commit('mutateItemValidProduct', payload)
    },
    loadItemsValidCusts({commit}, parentId){
      FPromotionRulesdValidCustsService.getAllFPromotionRulesdValidCustsByParent(parentId).then(
          response =>{
            commit('loadItemsValidCustsSuccess', response.data)
          },
          error =>{
            commit('loadItemsValidCustsFail')
            return Promise.reject(error)
          }
      )
    },
    loadItemsValidProducts({commit}, parentId){
      FPromotionRulesdValidProductsService.getAllFPromotionRulesdValidProductsByParent(parentId).then(
          response =>{

            commit('loadItemsValidProductsSuccess', response.data)
          },
          error =>{
            commit('loadItemsValidProductsFail')
            return Promise.reject(error)
          }
      )
    }

  },
  mutations: {
    mutateItemModified(state, payLoad){
      state.itemModified = payLoad
    },
    mutateItemValidCust(state, payLoad){
      state.itemValidCust = payLoad
    },
    mutateItemValidProduct(state, payLoad){
      state.itemValidProduct = payLoad
    },
    loadItemsValidCustsSuccess(state, items){
      state.itemsValidCusts = items
    },
    loadItemsValidCustsFail(state){
      state.itemsValidCusts = []
    },
    loadItemsValidProductsSuccess(state, items){
      state.itemsValidProducts = items
    },
    loadItemsValidProductsFail(state){
      state.itemsValidProducts = []
    },


  },


};
